*{
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
}
body{
    margin: 0;
    font-family: 'SFPRODISPLAYREGULAR';    
}

.sweet-loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 16%);
    z-index: 9999;
}

.page-not-found{
    min-height: 80vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 110px;
    .image404{
        height: 60vh;
    }
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  cursor: pointer;
}
