@import '../../../styles.scss';
.header-wrapper{
    box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
    @media (max-width: 1024px) {
        padding: 0px 15px;
    }
    .header-logo{
        cursor: pointer;
        @media (max-width: 1024px) {
            width: 90px;
        }
    }
    .mid-wrap{
        display: inline-flex;
        .a-link{
            cursor: pointer;
            @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
            padding: 24px 24px;
        }
    }
    .right-wrap{
        display: flex;
        align-items: center;
        .language-block{
            display: flex;
            align-items: center;
            margin-right: 12px;
            margin-left: 12px;
            cursor: pointer;
            @media (max-width: 599px) {
                margin-right: 0px;
                margin-left: 0px;                
            }
            .language-img{
                width: 24px;
                height: 24px;
                border-radius: 50%;
            }
            .language-text{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM',);
                margin-right: 8px;
                margin-left: 8px;
                text-transform: capitalize !important;
                @media (max-width: 599px) {
                    display: none;
                }
            }
        }
        .header-icon{
            cursor: pointer;
            padding: 32px 12px;
        }
        .header-btn{
            margin-right: 12px !important;
            @media (max-width: 599px) {
                margin-right: 0px !important;
                min-height: 40px;
                padding: 10px 12px !important;
            }
            .header-btn-icon{
                margin-left: 8px;
                @media (max-width: 599px) {
                    display: none;
                }
            }
        }
    }
}
#language-header-menu{
    .MuiPaper-root{
        transform: translate(-5px, 18px) !important;
        .language-img{
            margin-left: 8px;
        }
    }
}