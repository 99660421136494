@import "../../styles.scss";

.manage-category-wrapper {
    .store-detail-head {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .h3 {
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM', );
            margin: 0 0px 0 12px;

            @media (max-width: 899px) {
                margin: 0 0px 10px 0px;
            }
        }
    }

    .mx-2 {
        margin: 0px 8px;
        cursor: pointer;
    }

    .left-side-new {
        @media (max-width: 1199px) {
            margin-bottom: 20px;
        }
        .MuiTreeView-root {
            height: 100% !important;
        }

        .MuiTreeView-root {
            .MuiTreeItem-root {
                &.main-tree {
                    .MuiTreeItem-content {
                        display: flex;
                        align-items: center;

                        .MuiTreeItem-label {
                            font-weight: 400;
                            font-size: 14px !important;
                            background-color: transparent !important;
                            font-family: 'SFPRODISPLAYREGULAR' !important;
                            padding: 0px;

                            .MuiTypography-root {
                                font-size: 14px !important;
                                font-family: 'SFPRODISPLAYREGULAR' !important;
                            }
                        }

                        .MuiTreeItem-iconContainer {
                            color: var(--text);
                            align-items: center;
                            width: 24px !important;
                            height: 24px;
                            margin-right: 0px !important;
                            transform: rotate(0deg);

                            &::after {
                                content: '';
                                position: absolute;
                                background-image: url('../../assets/images/structure/arrow-black-down.svg');
                                width: 24px;
                                height: 24px;
                                left: 0px;
                                transform: rotate(0deg);
                            }

                            svg {
                                display: none;
                                font-size: 24px !important;
                            }
                        }

                    }

                    &[aria-expanded="true"] {
                        .MuiTreeItem-iconContainer {
                            transform: rotate(180deg) !important;
                        }
                    }

                }

                &.main-tree {
                    margin-bottom: 0px;

                    .MuiTreeItem-content {
                        padding: 8px 6px 8px 34px !important;
                        position: relative;

                        .MuiTreeItem-iconContainer {
                            position: absolute;
                            left: 8px;
                            margin: 0px 0px;
                        }
                    }

                    .MuiCollapse-root {
                        margin: 0px 0px 0px 0px !important;

                        .MuiTypography-root {
                            font-size: 14px !important;
                            font-family: "SFPRODISPLAYREGULAR" !important;
                        }

                        .MuiFormControlLabel-root {
                            margin: 4px 0px;
                            align-items: flex-start;

                            .MuiCheckbox-root {
                                margin: 0px 0px 0px 10px;
                            }

                            .MuiFormControlLabel-label {
                                font-family: "SFPRODISPLAYREGULAR" !important;
                            }
                        }

                        .MuiCollapse-wrapperInner {
                            .MuiTreeItem-root {
                                .MuiTreeItem-content {
                                    background: transparent;
                                    margin-top: 0px;
                                    padding: 8px 20px 8px 34px !important;

                                    .MuiTreeItem-iconContainer {
                                        &::after {
                                            content: '';
                                            position: absolute;
                                            background-image: url('../../assets/images/structure/plus-icon1.svg');
                                            background-size: contain;
                                            width: 18px;
                                            height: 18px;
                                            left: auto;
                                        }
                                    }


                                }

                                &[aria-expanded="true"] {
                                    .MuiTreeItem-iconContainer {
                                        &::after {
                                            background-image: url('../../assets/images/structure/minus-icon1.svg');
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .searches-block {
        position: relative;
        width: 100% !important;
        margin-bottom: 12px;

        .MuiOutlinedInput-input {
            padding: 6.5px 48px 6.5px 12px !important;
        }
    }

    .top-row-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        @media (max-width: 480px) {
            flex-direction: column;
            justify-content: flex-start;
        }
        .h3 {
            margin: 0px 0px;
            @media (max-width: 480px) {
                width: 100%;
            }
        }

        .d-flex {
            display: flex;
            align-items: center;
            @media (max-width: 480px) {
                &.relative{
                    width: 100%;
                    justify-content: flex-end;
                    margin-top: 10px;
                }
            }
        }

        .icon16 {
            width: 16px;
            height: 16px;
            margin: 0px 4px;

            &.mx-2 {
                margin: 0px 8px;
            }
        }

        .p4 {
            margin: 0px;
            font-size: 14px;
        }
    }

    .category-boxed {
        padding: 16px;
        position: relative;
        background: $white;
        border-radius: 8px;
        border: 1px solid #EEE;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
        @media (max-width: 480px) {
            flex-direction: column;
        }
        .category-left {
            display: flex;
            align-items: center;
            @media (max-width: 480px) {
                width: 100%;
            }
            .img40 {
                width: 40px;
                height: 40px;
                border-radius: 8px;
                object-fit: cover;
            }

            .h6 {
                font-size: 14px;
                font-weight: 400;
                margin: 0px 30px;
                width: auto;
                max-width: 400px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                @media (max-width: 767px) {
                    margin: 0px 10px;
                }
            }
        }

        .category-right {
            display: flex;
            align-items: center;
            @media (max-width: 480px) {
                width: 100%;
            }
            .p4 {
                font-size: 14px;
                white-space: nowrap;

                &.mx-16 {
                    margin: 0px 16px;
                }
            }
        }
    }
    .category-boxedinner {
        padding: 8px 6px 8px 34px !important;
    }
}

.main-modal.maxwidth-600 {
    .MuiDialog-paper {
        width: 100%;
    }
}

.timeslots-content {
    max-height: calc(100vh - 150px);
    padding: 10px 0px 60px 0px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;

    @media (max-width: 767px) {
        padding: 10px 0px 90px 0px;
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
}

.timeslots-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 45px);
    background: $white;
    z-index: 9;
}
.category-img-row{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 480px) {
        flex-direction: column;
    }
    .category-upload{
        width: 96px;
        height: 96px;
        border-radius: 12px;
        background: #D9D9D9;
        .category-img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .upload-btn{
        margin: 0px 24px 0px 12px;
        @media (max-width: 480px) {
            margin: 20px 0px 6px 0px;
        }
        input[type="file"] {
            position: absolute;
            inset: 0px;
            opacity: 0;
        }
    }
}