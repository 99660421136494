@import "../../styles.scss";
.manage-workinghours-wrapper{
    padding-top: 70px;
    width: 100%;
    .manage-workinghours-head{
        background-color: var(--background);
        padding: 20px 36px;
        text-align: center;
        margin: 0 0 0px 0;
        height: 145px;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 0 0;
        }
    }
    .working-hours-block{
        margin-top: -80px;
        .card-white{
            padding: 20px 20px 20px 20px;
            border: 1px solid var(--tab);
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            min-height: calc(100vh - 235px);
        }
        .h4{
            @include h4-heading($text, $family: 'SFPRODISPLAYMEDIUM',) ;
            margin: 0 0 8px 0;
        }
        .p2{
            @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',) ;
            margin: 0 0 20px 0;
            &.text-primary{
                color: $primary;
                margin: 0 0 0px 0;
            }
        }
    }
    .checkbox-block{
        display: flex;
        align-items: center;
        .p2{
            @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
            margin: 0 10px 0 4px;
        }
        
    }
}

.disable-hours {
    pointer-events: none;
    opacity: 0.5;
}
.MuiGrid-root.MuiGrid-container{
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12:last-child{
        .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12{
            .MuiDivider-root.MuiDivider-fullWidth{
                display: none;
            }
        }
    }
}

.text-info {
    color: cornflowerblue;
}
.bold {
    font-weight: bolder;
}