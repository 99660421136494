@import '../../../styles.scss';
.header-wrapper2{
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: var(--white);
    box-shadow: 0px 2px 4px rgb(0 0 0 / 4%);
    padding: 10px 0;
    z-index: 1300;
    @media (max-width: 1024px) {
        padding: 10px 15px;
    }
    .header-logo{
        cursor: pointer;
        @media (max-width: 1024px) {
            width: 90px;
        }
    }
    .mid-wrap{
        display: inline-flex;
        .a-link{
            cursor: pointer;
            @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
            padding: 24px 24px;
        }
    }
    .right-wrap{
        display: flex;
        align-items: center;
        padding: 1px 0;
        .p2{
            @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
        }
        .p2-bold{
            @include p2-paragraph($text, $family: 'SFPRODISPLAYBOLD',);
            margin-right: 8px;
        }
        .language-block{
            display: flex;
            align-items: center;
            margin-right: 24px;
            cursor: pointer;
            .language-img{
                width: 24px;
                height: 24px;
                border-radius: 50%;
            }
            .language-text{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM',);
                margin-right: 8px;
                margin-left: 8px;
                text-transform: capitalize !important;
            }
        }
        .store-wrap{
            display: flex;
            align-items: center;
            padding-right: 20px;
            cursor: pointer;
            .img-block{
                width: 48px;
                min-width: 48px;
                height: 48px;
                border-radius: 200px;
                border: 1px solid var(--gray);
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                @media (max-width: 599px) {
                    width: 36px;
                    min-width: 36px;
                    height: 36px;
                }
                .logo-img{
                    width: 100%;
                    min-width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .content-block{
                padding-right: 8px;
                @media (max-width: 599px) {
                    display: none;
                }
                .h6{
                    @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
                    margin: 0 0 4px 0;
                }
                .p3{
                    @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                    margin: 0 0 0px 0;                    
                    white-space: nowrap;
                    max-width: 150px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
            
            &.disabled {
                background-color: $line !important;
                pointer-events: none;
                .content-block{
                    .h6{
                        @include h6-heading($gray);
                    }
                    .p3{
                        @include p3-paragraph($gray);
                    }
                }
            }
        }
        .online-switch-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: 12px;
            .p3{
                @include p3-paragraph($secondary, $family: 'SFPRODISPLAYMEDIUM',);
                cursor: pointer;
            }
        }
    }
}
#Logout-menu{
    .MuiPaper-root{
        transform: translate(0px, 18px) !important;
        &.MuiPaper-elevation{
            overflow:  visible !important;
        }
        .MuiButtonBase-root{
            padding: 8px 0px !important;
        }
    }
}
#shopName-menu{
    .MuiPaper-root{
        transform: translate(24px, 14px) !important;
    }
}
.MuiButtonBase-root{
    font-family: "SFPRODISPLAYMEDIUM" !important;
    font-size: 14px !important;
    color: $text !important;
    position: relative;
    transition: all 200ms;
    // padding: 8px 4px !important;
    .relative{
        position: relative;
        padding: 0px 8px 0px 0px;
    }
    .icon24{
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin: 0px 8px;
    }
    .p2{
        @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM',);
        margin: 0px;
    }
    .p4{
        @include p4-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
        margin: 0px;
    }    
    .language-menu{
        position: absolute;
        right: auto;
        left: 102%;
        top : 0;
        visibility: hidden;
        opacity: 0;
        transition: all 200ms;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 4%);
        padding: 8px 0;
        .language-menu-item{
            padding: 8px 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &:hover{
                background-color: var(--background);
            }
            .language-text{
                margin: 0px 8px;
            }
        }
    }
    &:hover{
        .language-menu{
            visibility: visible;
            opacity: 1;
        }
    }
    
    // first
    &.store-wrap{
        display: flex;
        align-items: center;
        padding-right: 20px;
        padding: 8px 10px !important;
        cursor: pointer;
        .img-block{
            width: 36px;
            min-width: 36px;
            height: 36px;
            border-radius: 50%;
            border: 1px solid var(--gray);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            .logo-img{
                width: 100%;
                min-width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }
        .content-block{
            padding: 0px 6px;
            .h6{
                @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
                font-size: 14px !important;
                margin: 0 0 0px 0;
            }
            .p4{
                @include p4-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                margin: 0 0 0px 0;                
                white-space: nowrap;
                max-width: 150px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
.profile-drop-parent{
    border-right: 1px solid $line;
    margin-right: 20px;
    @media (max-width: 599px) {
        margin-right: 10px;
        .MuiButtonBase-root{
            min-width: 30px;
        }
    }
}
.disabled {
    background-color: $line !important;
    pointer-events: none;
    .img-block{
        filter: contrast(0.5)
    }
    .content-block{
        .h6{
            @include h6-heading($gray !important);
        }
        .p4{
            @include p3-paragraph($gray !important);    
        }
    }
}

.no-event-btn {
    pointer-events: none !important;
}