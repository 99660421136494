@import "../../../styles.scss";
.footer-wrapper{
    background-color: var(--text);
    margin-top: 80px;
    .container-footer{
        width: 1106px;
        margin: auto;
        @media (max-width: 1260px) {
            width: 100%;    
            max-width: 1024px;
            padding: 0px 20px;
        }
    }
    .mid-wrap{
        padding-top: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid rgb(169 172 170 / 20%);
        .footer-logo{
            width: 162px;
        }
        .footer-social-wrap{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-top: 16px;
            padding-bottom: 32px;
            .social-icons{
                width: 32px;
                height: 32px;
                object-fit: contain;
                margin-left: 16px;
                cursor: pointer;
            }
        }
        .head{
            @include p1-paragraph($gray, $family: 'SFPRODISPLAYREGULAR',);
            display: block;
            &.line-top{
                display: block;
                width: 180px;
                border-top: 1px solid rgb(169 172 170 / 20%);
                margin: 24px 0px 0px 0px;
                padding-top: 24px;
            }
        }
        .p1{
            @include p1-paragraph($white, $family: 'SFPRODISPLAYMEDIUM',);
            display: block;
            margin-top: 24px;
            cursor: pointer;
            text-decoration: none;
        }
        .img-height41{
            height: 41px;
            width: auto;
            margin: 16px 0px;
            cursor: pointer;
            &+.img-height41{
                margin-right: 7px;
            }
        }    
        .a-red-bold{
            @include p1-paragraph($primary, $family: 'SFPRODISPLAYMEDIUM',);
            text-decoration-color: $primary;
            display: block;
            width: 100%;
            border-top: 1px solid rgb(169 172 170 / 20%);
            margin: 8px 0px 0px 0px;
            padding-top: 24px;
            cursor: pointer;
        }   
        .MuiGrid-grid-md-2,.MuiGrid-grid-sm-12{
            .head{
                @media (max-width: 599px) {
                    margin-top: 30px;
                }
            }
        } 
    }
    .bottom-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        .p2{
            display: flex;
            align-items: center;
            @include p2-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM',);
            @media (max-width: 360px) {
                font-size: 10px;
            }
        }
    }
}