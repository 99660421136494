@import "../../styles.scss";

.home-wrapper{
    .home-sec1{
        padding-top: 50px;
        @media  (max-width: 1024px) {
            padding-top: 30px;
        }
        .home-sec1-wrap{
            padding: 50px 60px;
            background-color: var(--background_light);
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            @media (max-width: 1024px) {
                padding: 30px 20px;
            }
            &::before{
                content: '';
                background-image: url('../../assets/images/structure/sec3-top-mask.png');
                width: 609px;
                height: 300px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                @media (max-width: 1024px) {
                    max-width: 609px;
                    width: 100%;
                }
            }  
            &::after{
                content: '';
                background-image: url('../../assets/images/structure/sec3-bottom-mask.png');
                width: 680px;
                height: 341px;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 0;
                @media (max-width: 1024px) {
                    max-width: 609px;
                    width: 100%;
                }
            }
            .sec1-left-block{
                position: relative;
                z-index: 5;
                .h1{
                    @include h1-heading($text, $family: 'SFPRODISPLAYBOLD');
                    margin: 30px 0 30px 0;
                    @media (max-width: 1024px) {
                        margin: 0px 0 20px 0;
                    }
                }
            }
            .sec1-right-block{
                position: relative;
                z-index: 5;
                @media (max-width: 1024px) {
                    text-align: center;
                }
                .sec1-right-block-img{
                    width: 499px;
                    height: 333px;
                    @media (max-width: 1024px) {
                        max-width: 499px;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
        
    }
    .home-sec2{
        .home-sec2-wrap{
            padding-top: 65px;
            @media (max-width: 1024px) {
                padding-top: 30px;
                padding-left: 20px;
                padding-right: 20px;
                  
            }
            .h2{
                @include h2-heading($text, $family: 'SFPRODISPLAYBOLD');
                margin: 0px 0 48px 0;
                @media (max-width: 1024px) {
                    margin: 0px 0 30px 0;
                }
            }
            .sec2-boxes-block{
                @media (max-width: 899px) {
                    margin-bottom: 20px;
                }
                .boxes-img-block{
                    background-color: var(--white);
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    padding: 18px 18px;
                    @media (max-width: 1024px) {
                        padding: 12px 12px;
                    }
                    .boxes-image{
                        width: 64px;
                        min-width: 64px;
                        height: 64px;
                        @media (max-width: 1024px) {
                            width: 48px;
                            min-width: 48px;
                            height: 48px;
                        }
                    }
                }
                .boxes-content-block{
                    padding-right: 24px;
                    .h3{
                        @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
                        margin: 0px 0 8px 0;
                    }
                    .p1{
                        @include p1-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                        margin: 0px 0 0px 0;
                    }
                }
            }
        }
    }
    .home-sec3{
        .home-sec3-wrap{
            padding: 65px 60px 0px 60px;
            @media (max-width: 1024px) {
                padding: 65px 20px 0px 20px;
            }
            .h2{
                @include h2-heading($text, $family: 'SFPRODISPLAYBOLD');
                margin: 0px 0 48px 0;
                @media (max-width: 1024px) {
                    margin: 0px 0 30px 0;
                
                    
                }
            }
            .slick-slide{
                text-align: center;
            }
            .about-client-box:hover{
                cursor: pointer;
            }
            .about-client-box{
                border: 2px solid var(--secondary);
                border-radius: 10px;
                padding: 16px 20px;
                text-align: center;
                width: 344px !important;
                @media (max-width: 1299px) {
                    width: calc(100% - 20px) !important;
                }
                @media (max-width: 1024px) {
                    width: calc(100% - 20px) !important;
                }
                margin: auto;
                .client-box-img{
                    width: 120px;
                    min-width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    margin: 0 auto 16px auto;
                }
                .h3{
                    @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
                    margin: 0px 0 12px 0;
                }
                .star-showing{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .p2{
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                    margin: 12px 0 0px 0;
                }
            }
        }
    }
}