@import "../../../styles.scss";
.order-listing-wrapper{
    .store-detail-head{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
            margin: 0 0px 0 12px;
            @media (max-width: 899px) {
                margin: 0 0px 10px 0px;
            }
        }
    }
    .searches-block {
        position: relative;
        width: 260px;
        margin-left: 20px;
        @media (max-width: 599px) {
            width: 100%;
            margin-left: 10px;
        }
    }
    .disabled{
        position: relative;
        th{
            color: var(--gray) !important;
        }
        td{
            color: var(--gray) !important;
            &:last-child{
                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color:  rgba(247, 250, 248, 0.5) !important;
                    z-index: 99;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
    }
}

.border-left-orange{
    border-left: 4px solid orange !important;
    border-left-color: orange !important;
}
.border-left-red {
    border-left: 4px solid red !important;
    border-left-color: red !important;
}

.pointer {
    cursor: pointer !important;
}

.pagination-wrap {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        li {
            list-style: none;
            margin: 0 5px;
            a {
                color: var(--gray);
                font-size: 14px;
                font-weight: 500;
                padding: 5px 10px;
                border-radius: 4px;
                &:hover {
                    background-color: var(--gray);
                    color: var(--white);
                }
            }
            &.active {
                a {
                    background-color: var(--gray);
                    color: var(--white);
                }
            }
        }
    }
}