@import "../../../styles.scss";

.add-product-wrapper {
    .add-product-head {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .h3 {
            @include h3-heading($text, $family: 'SFPRODISPLAYBOLD', );
            margin: 0 12px 0 0;
            &.mx-0{
                margin: 0px 0px 0 0 !important;
            }
        }

        .icon24 {
            width: 24px;
            height: 24px;
            object-fit: contain;
            transform: scaleX(-1);
        }
    }

    .card-white {
        margin-bottom: 20px;

        .h6 {
            @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM', );
            margin: 0 0 30px 0;
            display: flex;
            align-items: center;
            .info-icon{
                margin: 0px 6px;
            }

            &.mb-0 {
                margin-bottom: 0px;
            }
        }

        .p2 {
            @include p3-paragraph($text);
            margin-bottom: 20px;
        }
        .min-max-row{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 1199px) {
                margin-bottom: 24px;
            }
            .h6{
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: 400;

                .info-icon{
                    margin: 0px 6px;
                }
            }
            .inc-dec-box{
                border: 1px solid var(--gray);
                height: 56px;
                width: 140px;
                border-radius: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .input-box{
                    height: 100%;
                    width: 100%;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    border-radius: 0.5rem;
                    text-align: center;
                }
                /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
                .dec-btn,.inc-btn{
                    min-width: 48px;
                    font-size: 24px;
                background-color: transparent;
            border: none;box-shadow: none;outline: none;                }
            }
        }

     .switch-label{
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        margin-top: 18px;
        &.mx-2{
            @media (max-width:991px) {                
                margin: 18px 8px 0px -10px;
              }
            @media (max-width:899px) {                
              margin: 18px 8px;
            }
        }
        .mx-2{
            margin: 0px 8px;
        }
     }   
     .addon-delete-row{
        position: relative;        
        @media (max-width: 599px) {
            padding-left: 20px;
        }
        .MuiFormControl-root.MuiTextField-root{
            @media (max-width: 899px) {
                margin-bottom: 20px;
            }
        }
        .delete-icon{
            position: absolute;
            bottom: auto;
            top: 18px;
            right: auto;
            left: -10px;
            background-color: transparent;
            border: none;
            box-shadow: none;
            outline: none;
            padding: 0px;
            margin: 0px;
            cursor: pointer;
            &:hover{
                background-color: transparent;
            }
            @media (max-width: 599px) {
                left: -5px
            }
        }
     }
     .heading-row{
        display: flex;
        align-items:center;
        justify-content: space-between;
        margin-bottom:10px;
     }
    }
    .bg-white{
        background-color: $white;
        border-radius: 8px;
        padding-top: 20px;
        margin-bottom: 40px;
        
    }
    .btn-row {
        display: flex;
        justify-content: flex-end;
    }
}

.no-event {
    pointer-events: none !important;
    opacity: 0.9 !important;
    cursor: not-allowed !important;
}
.mb-30{
    margin-bottom: 30px;
}
