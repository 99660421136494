body[style~="padding-left:17px"]{
    background-color: red !important;
    &[style~="padding-right:4px"]{
        padding-right: 0px !important;
    }
}
// breadcrumbs
.breadcrumbs-wrapper {
    display: flex;
    align-items: center;
    padding: 24px 0;

    .breadcrumb-text {
        @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR', );

        &.links {
            color: $gray;
            cursor: pointer;
        }
    }
}

// Search Results
.search-results-block {
    margin-top: 32px;

    .p1 {
        @include p1-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
    }

    .p1-semibold {
        @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
    }
}

// Products Card
.products-block {
    .product-box {
        background: $white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 16px 16px;
        position: relative;
        margin-bottom: 32px;

        .wishlist {
            background-image: url('../images/structure/wishlist-black.svg');
            width: 24px;
            height: 24px;
            cursor: pointer;
            display: flex;
            position: absolute;
            left: 16px;
        }

        .product-image {
            width: 143px;
            min-width: 143px;
            height: 143px;
            margin: 0 auto 24px auto;
            border-radius: 8px;
        }

        .p1 {
            @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 12px 0;
            height: 40px;
            overflow: hidden;
        }

        .product-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .p2 {
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 0px 0;
            }
        }
    }
}

// Recommended Card
.recommended-stores-block {
    background-color: var(--background_light);
    padding: 32px 0;
    margin-top: 40px;

    .recommended-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .h3 {
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM', );
            margin: 0;
        }

        .p1 {
            @include p1-paragraph($primary, $family: 'SFPRODISPLAYMEDIUM', );
            margin: 0;
        }
    }

    .recommended-card-box {
        width: 250px;
        margin-top: 32px;
        padding-bottom: 4px;
        padding-left: 16px;
        padding-right: 16px;

        .recommended-image {
            width: 100%;
            min-width: 100%;
            height: 250px;
            border-radius: 10px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
        }

        .recommended-content-box {
            margin-top: -90px;
            position: relative;
        }

        .recommended-content {
            background: $white;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 8px 8px;
            text-align: center;

            .h6 {
                @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM', );
                margin: 0 0 4px 0;
            }

            .p2 {
                @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR', );
                margin: 0 0 4px 0;
            }

            .p2-mins {
                margin: 0 0 0 8px;
            }
        }
    }
}

// Loader
.loader-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Search Input
.searches-block {
    position: relative;
    width: 450px;
    @media (max-width: 599px) {
        width: 100%;

    }

    .searches-icon {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 1;
        cursor: pointer;
    }
}
.manage-category-wrapper{
    .searches-block{
        width: 100%;
        .searches-icon{
            top: 6px;
        }
    }
}
// Text Center
.text-center {
    text-align: center;
}

// Main Mid
.full-bg {
    background-color: var(--background_light);
}

.main-wrapper {
    display: block;
}
.header-wrapper2~.main-wrapper{
    display: flex;
}

.main-mid-wrapper {
    padding: 100px 30px 30px 30px;
    width: calc(100vw - 250px);
    min-height: calc(100vh - 0px);
    @media (max-width: 1199px) {
        padding: 100px 0px 20px 20px;
    }
    @media (max-width: 899px) {
        width: calc(100vw - 0px);
        padding: 100px 20px 20px 20px;
    }
    &.pb-0{
        padding-bottom: 0px;
    }
}

.card-white {
    background-color: $white;
    border-radius: 8px;
    border: 1px solid var(--tab);
    padding: 20px 30px 30px 30px;
    @media (max-width: 599px) {
        padding: 20px 16px 30px 16px;
    }
}

// For Auth Pages
.login-otp {
    @include p2-paragraph($secondary, $family: 'SFPRODISPLAYREGULAR', );
    cursor: pointer;
}

.forgot-password {
    @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR', );
    cursor: pointer;
}

.dont-account {
    @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR', );
}

.sign-up {
    @include p2-paragraph($primary, $family: 'SFPRODISPLAYMEDIUM', );
    cursor: pointer;
    margin-right: 4px;
}

.redspan {
    color: var(--primary);
    cursor: pointer;
    margin-top: 3px;
    margin-right: 0px;
    margin-bottom: 0;
    margin-left: 0px;
    @include p2-paragraph($primary, $family: 'SFPRODISPLAYMEDIUM');
}

.errorspan {
    color: var(--primary);
    position: absolute;
    display: block;
    font-size: 12px;
    bottom: -15px;

    .bottom-18 {
        bottom: 18px;
    }
}


// start vinay css Custome
.MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
}

//CK Editor
.ck {
    .ck-toolbar__items{
        flex-wrap: wrap !important;
    }
    .ck-toolbar {
        border-radius: 4px 4px 0px 0px !important;
        border-color: #A9ACAA !important;
    }

    .ck-content {
        border-radius: 0px 0px 4px 4px !important;
        border-color: #A9ACAA !important;
    }
}

.border-bottom{
    border-bottom: 1px solid $line !important;
}
.calender-icon{    
    .MuiSelect-select{
        padding: 8.5px 40px !important;
        position: relative;
    }
    .MuiInputBase-root{
        position: relative;
        background: $white;
        padding: 0px 12px 0px 12px;
        .MuiInputBase-input{
            font-size: 14px !important;
            padding-right: 0px !important;
            padding-left: 0px !important;
        }
        .MuiInputAdornment-root{
            opacity: 0;
            .MuiButtonBase-root{
                position: absolute;
                left: 0px;
                width:100%;
            }
        }
        &::before{
            content: '';
            position: absolute;
            background: url('../images/structure/Calendar.svg') no-repeat;
            width: 24px;
            height: 24px;
            left: 9px;
        }
        .MuiSvgIcon-root{
            opacity: 0;
        }
    }
    &.width160{        
        max-width: 160px;
        min-width: 160px;
        &.mx-2{
        margin: 0px 16px;
        }
        .MuiInputBase-root{
            width: 160px;
        }
    }
    &.width130{        
        max-width: 130px;
        min-width: 130px;
        .MuiInputBase-root{
            width: 130px;
        }
    }
}
.payout-status{
    min-width: auto;
    .MuiInputAdornment-root{
        .MuiTypography-root{
            @include p3-paragraph($gray, $family: 'SFPRODISPLAYREGULAR');
        }
    }
    .MuiSelect-select{
        @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
        font-size: 14px !important;
        min-width: auto;
        padding-left: 18px ;
        padding-right: 4px !important;
    }
}
.empty-box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - 170px);
    text-align: center;
    .empty-img{
        width: 64px;
    }
    .h4{
        @include h4-heading(#1E1142, $family: 'SFPRODISPLAYMEDIUM');
        margin: 16px 0 12px 0;        
    }
    .p2{
        @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
        margin: 0 0 20px 0;
    }
    .MuiButtonBase-root{
        min-width: 240px;
    }
}
.text-right-left{
    text-align: left;
}
.rotate180{
    transform: rotate(180deg);
}
.g-recaptcha{
    @media (max-width: 360px) {
        transform: scale(0.80);
        transform-origin: 0 0;
    }
}
.top-flex-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 899px) {
        flex-direction: column;
        align-items: flex-start;
        .MuiButtonBase-root{
            white-space: nowrap;
            width: 130px !important;
        }
        .calender-icon{
            margin: 0px;
            &.mx-2{
                margin: 8px 0px;
                }
        }
    }
    .flex-wrap-mobile{
        @media (max-width: 599px) {
            position: relative;
            width: 100%;
            flex-wrap: wrap;
            .calender-icon.width160{
                max-width: 100%;
                min-width: 100%;
                 .MuiInputBase-root{
                    width: 100%;
                 }
            }
            .payout-status{
                width: 100%;
                margin: 10px 0px;
            }
            .MuiButtonBase-root{
                width: 100%;
            }
        }
    }
}

.payout-status{
    min-width: auto;
    .MuiInputAdornment-root{
        .MuiTypography-root{
            @include p3-paragraph($gray, $family: 'SFPRODISPLAYREGULAR');
        }
    }
    .MuiSelect-select{
        @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
        font-size: 14px !important;
        min-width: auto;
        padding-left: 18px ;
        padding-right: 4px !important;
    }
}
.top-flex-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 899px) {
        flex-direction: column;
        align-items: flex-start;
        .MuiButtonBase-root{
            white-space: nowrap;
            width: 130px !important;
        }
        .calender-icon{
            margin: 0px;
        }
    }
    .flex-wrap-mobile{
        @media (max-width: 599px) {
            position: relative;
            width: 100%;
            flex-wrap: wrap;
            .calender-icon.width160{
                max-width: 100%;
                min-width: 100%;
                 .MuiInputBase-root{
                    width: 100%;
                 }
            }
            .payout-status{
                width: 100%;
                margin: 10px 0px;
            }
            .MuiButtonBase-root{
                width: 100%;
            }
        }
    }
}
.disabled50{
    opacity: 0.5;
    pointer-events: none;
    &.outline-none{
        .MuiOutlinedInput-notchedOutline{
            border-color: transparent !important;
        }
        .bg-pink{
            border-color: transparent !important;
        }
    }
}
.img-crop-modal{
    z-index: 9999 !important;
}
.mobile-bottom{
    @media (max-width: 410px) {
        position: relative;
        bottom: -36px;
        font-size: 12px;
    }
}
.plus-minus-icon{
    margin-top: 16px;
    cursor: pointer;
}
.mx-1{
    margin: 0px 4px;
}
.mx-2{
    margin: 0px 8px;
}
.mb-0{
    margin-bottom: 0px;
}
.offer-item-box{
    border: 1px solid var(--Grey, #A9ACAA);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: start;
    justify-content: start;
    @media (max-width: 767px) {
        flex-direction: column;
    }
    .relative{
        min-width: 300px;
        max-width: 300px;
        @media (max-width: 767px) {
            min-width: 100%;
             max-width: 100%;
        }
        &+.relative{
            width: calc(100% - 300px);
            min-width: calc(100% - 300px);
            border-right: 1px solid var(--Grey, #A9ACAA);      
            @media (max-width: 767px) {
                min-width: 100%;
                 width: 100%;
                 border-right: none;      
            }      
        }
    }
    .offer-items-header{
        background: var(--Background, #FFEEDA);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px;
        .h6{
            font-size: 12px;
            color: #252525;
            margin: 0px 0px;
            display: flex;
            align-items: center;
            .MuiCheckbox-root {
                margin: 0px 4px;
            }
        }
    }
    .offer-item-body{
        min-height: 280px;
        max-height: 280px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-color: #f1f1f1 transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: transparent;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #f1f1f1;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-track {
            border: 0.0625rem solid transparent;
            border-radius: 2px;
        }
        .offer-item-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 16px;
            border-bottom: 1px solid var(--line, #EEE);
            &:last-child{
                border-bottom: none;
            }
            .h6{
                font-size: 14px;
                color: #252525;
                margin: 0px 0px;
            }
            .font12-bold{
                font-size: 12px;
                color: #252525;
                font-family: 'SFPRODISPLAYMEDIUM';
                margin: 0px 0px;
            }
            .relative{
                width: auto;
                min-width: auto;
            }
        }
        .offer-item-empty{
            height: 280px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .h6{
                font-size: 14px;
                color: #252525;
                margin: 16px auto;
                max-width: 150px;
                text-align: center;
            }
        }
    }
}
.search-300{
    width: 300px;
    margin: 20px 0px 24px 0px;
    @media (max-width: 767px) {
        width: 100%;
    }
    .searches-block{
        width: 100%;
    }
}
.text-left{
    text-align: left;
}
.text-primary{
    color: $primary;
}
.table-switch-box{
    background: $background;
    padding: 8px 16px 8px 24px;
    border-radius: 8px;
    .mx-3{
        margin: 0px 16px;
    }
    .h6{
        margin: 0px;font-size: 16px;
    }
    span{
        font-size: 12px;
    }
}


.pointer {
    cursor: pointer !important;
}

.MuiList-root.store-dropdown{
    padding: 0px !important;
    .MuiListItem-root.MuiListItem-padding{
        padding: 10px 10px !important;
        .MuiTypography-root{
            &.MuiTypography-body1 {
                font-size: 16px;
                font-weight: 600;
            }
            &.MuiTypography-body2{
                font-size: 12px;
            }
        }
    }
    .MuiListItemText-root{
        margin: 0px 10px !important;
    }
}
.max-w-990{
    width: 100%;
    max-width: 990px;
}
.max-w-810{
    width: 100%;
    max-width: 810px;
}
.max-w-548{
    width: 100%;
    max-width: 548px;
}
.max-w-720{
    width: 100%;
    max-width: 720px;
}
.store-switch-box{
    height: 100%;
    max-height: calc(100vh - 245px);
    overflow: auto;
    position: relative;
    margin: 0px -10px;
    padding: 0px 10px;
    .store-switch-flex{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #E3E3E3;
        padding: 16px 0px;
        &:last-child{            
            border-bottom: none;
        }
        .store-logo{
            min-width: 36px;
            width: 36px;
            height: 36px;
            border-radius: 8px;
            padding: 4px;
            object-fit: scale-down;
            border: 1px solid #E8E8E8;
        }
        .relative{
            margin: 0px 8px;
            .mt-1{
                margin: 0px 0px 4px 0px;
            }
            .m-0{
                margin: 0px 0px;
            }
        }
    }
}
// end Vinay css Custom

.MuiList-root.holiday-list{
    padding: 0px !important;
    .MuiListItem-root.MuiListItem-padding{
        padding: 10px 10px !important;
        .MuiTypography-root{
            &.MuiTypography-body1 {
                font-size: 16px;
                font-weight: 600;
            }
            &.MuiTypography-body2{
                font-size: 12px;
                border-bottom: 1px solid #E3E3E3;
            }
        }
    }
    .MuiListItemText-root{
        margin: 0px 10px !important;
    }
}

.mx-4{
    margin-right: 16px;
    margin-left: 16px;
}