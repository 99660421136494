@import "../../styles.scss";
.notification-wrapper{
    .store-detail-head{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
            margin: 0 12px 0 0;
            @media (max-width: 899px) {
                margin: 0 0px 0px 0px;
            }
        }
    }
    
    .my-notification-wrap{
        background: var(--white);
        border-bottom: 1px solid var(--line);
        border-radius: 8px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        .img-block{
            background: var(--background);
            border-bottom: none;
            border-radius: 50%;
            position: relative;
            height: 40px;
            .logo-icon{
                width: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: scale-down;
            }
            .notification-signal{
                width: 8px;
                min-width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--primary);
                display: flex;
                position: absolute;
                top: 4px;
                right: 1px;
            }
        }
        .relative{
            margin: 0 16px 0 16px;
            .address-block{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                width: 100%;
                margin-top: 0px;
                margin-bottom: 6px;
            }
            .p3{
                @include p3-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0px 0 0;
            }
        }
        &.readied{
            .relative{
                .address-block{
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                }
            }
        }
    }
    
    
    
}