:root {
    --white: #FFFFFF;
    --primary: #ED4731;
    --secondary: #1EA896;
    --tertiary: #FBBB4A;
    --text: #252525;
    --surface-variant: #49454F;
    --gray: #A9ACAA;
    --background: #FFEEDA;
    --tab: #F7FAF8;
    --background_light: #FFF8F3;
    --line: #eeeeee;
}

$primary: #ED4731;
$secondary: #1EA896;
$tertiary: #FBBB4A;
$text: #252525;
$surface-variant: #49454F;
$white: #FFFFFF;
$gray: #A9ACAA;
$background: #FFEEDA;
$tab: #F7FAF8;
$background_light: #FFF8F3;
$line: #eeeeee;


.bg-white{
    background-color: var(--white);
}
