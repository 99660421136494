// Buttons
.MuiButton-containedPrimary {
    box-shadow: none !important;
    border-radius: 8px !important;
    color: var(--white) !important;
    background-color: var(--primary) !important;
    text-transform: capitalize !important;
}

.MuiButton-outlinedPrimary {
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
    text-transform: capitalize !important;
}

.MuiButton-sizeLarge {
    line-height: 1 !important;
    padding: 12px 16px !important;
    min-height: 48px;
    $family: 'SFPRODISPLAYMEDIUM';
    font-size: 16px !important;
}
.MuiButton-sizeMedium {
    min-height: 40px;
    line-height: 1 !important;
    $family: 'SFPRODISPLAYMEDIUM';
    font-size: 14px !important;
    border-radius: 0.5rem !important;
}
.MuiButton-sizeSmall{
    min-height: 36px;
    line-height: 1 !important;
    $family: 'SFPRODISPLAYMEDIUM';
    font-size: 14px !important;
    border-radius: 0.5rem !important;
}

.secondary-gray {
    background-color: var(--gray) !important;
}

.secondary {
    background-color: var(--secondary) !important;
}

.secondary-outline-gray {
    color: var(--gray) !important;
    background-color: var(--white) !important;
    border: 1px solid var(--gray) !important;
}

.secondary-outline-gray-disable {
    color: var(--white) !important;
    background-color: var(--gray) !important;
    border: 1px solid var(--gray) !important;
}
// Inputs
.home-search-input {
    border-radius: 10px !important;

    .MuiOutlinedInput-input {
        padding-left: 140px;
    }
}

.searches-input {
    border-radius: 10px !important;

    .MuiOutlinedInput-input {
        padding-right: 48px;
    }
}

.MuiFormControl-root {
    width: 100%;
}

.MuiInputLabel-outlined {
    @include p1-paragraph($gray !important, $family: 'SFPRODISPLAYREGULAR' !important, );
}

.MuiOutlinedInput-input {
    @include p1-paragraph($gray, $family: 'SFPRODISPLAYREGULAR' !important, );
    font-size: 16px !important;
}

.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
        border-color: var(--tertiary) !important;

    }

    &.MuiInputLabel-outlined {
        color: var(--tertiary) !important;
        
    }
}

.MuiOutlinedInput-notchedOutline {
    border-color: var(--gray) !important;
    border-radius: 8px !important;
    text-align: right !important;
}

.otp-input {
    .MuiInputBase-input {
        text-align: center;
    }
}

.form-group {
    margin-bottom: 24px;
    position: relative;

    .redspan {
        position: absolute;
        left: auto;
        right: 0px;
        bottom: -15px;
        font-size: 12px;
    }
}

.MuiFormHelperText-sizeMedium {
    color: var(--primary) !important;
    @include p2-paragraph($primary, $family: 'SFPRODISPLAYMEDIUM' !important);
    font-family: 14px !important;
}

.MuiInputLabel-root {
    text-align: right !important;
    transform-origin: top right !important;
    left: unset !important;
    right: 0;
    transform: translate(-14px, 16px) scale(1) !important;

    &.MuiInputLabel-shrink {
        transform: translate(-14px, -9px) scale(0.75) !important;
    }
    &.Mui-disabled{        
        transform: translate(-12px, -8px) scale(0.75) !important;
        background: #fff !important;
    }
}

.MuiFormControl-root {
    
    .Mui-disabled {
        background-color: var(--tab) !important;

        &::after {
            content: '';
            position: absolute;
            background-image: url('../images/structure/check-green.svg');
            width: 24px;
            height: 24px;
            left: 12px;
        }
    }

    .MuiInputLabel-root {
        &.Mui-disabled {
            &::after {
                display: none;
            }
        }
    }
    .MuiAutocomplete-inputRoot{
        .MuiAutocomplete-popupIndicator{
            &.Mui-disabled {
                &::after {
                    display: none;
                }
            }
        }
        &.Mui-disabled {
            &::after {
                display: none;
            }
        }
    }

    .MuiOutlinedInput-input {
        &.Mui-disabled {
            &::after {
                display: none;
            }
        }
    }
    [class*="MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root"]{
        &.Mui-disabled {
            &::after {
                display: none;
            }
        }
    }
}

.MuiSelect-iconOutlined {
    right: unset !important;
    left: 7px;
}
.working-hours-block{
    .Mui-disabled{
        .MuiSelect-iconOutlined{
            display: none;
        }
    }
}

.sortby-dropdown {
    .MuiSelect-outlined {
        padding: 8.5px 14px 8.5px 45px;
    }

    .MuiSelect-icon {
        background-image: url('../images/structure/arrow-black-down.svg');

        path {
            display: none;
        }
    }
}

.MuiTextField-root {
    width: 100% !important;
}

.MuiFormLabel-asterisk {
    color: var(--primary);
}

// tabs
.border-bottom{
    border-bottom: 1px solid #E3E3E3;
    margin-bottom: 8px;
}
.order-listing-wrapper{
    .mx-0{
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .MuiTabs-root{
        align-items: center !important;
        .MuiTab-root {
            min-width: 100px !important;
        }  
    }
    .MuiPaper-root.MuiPaper-elevation{
        min-height: 150px;
    }
}
.MuiTab-root {
    min-width: 180px !important;
    &.Mui-selected{
        font-family: "SFPRODISPLAYBOLD"  !important;
    }
}

.MuiTab-textColorPrimary {
    @include p2-paragraph($text !important, $family: 'SFPRODISPLAYMEDIUM' !important, );
    text-transform: capitalize !important;
    font-weight: 600 !important;
}

.MuiTabs-indicator {
    background-color: var(--primary) !important;
}

.tabs-block {
    .tabs-head {
        background-color: var(--tab);
    }
}
.home-sec3{
    .slick-prev, .slick-next{
        background: var(--primary);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: 40px !important;
        height: 40px !important;
    }
    .slick-prev{
        right: auto !important;
        left: -40px !important;
        @media (max-width: 1024px) {
            left: -20px !important;
        }
    }
    .slick-next{
        left: auto !important;
        right: -40px !important;
        @media (max-width: 1024px) {
            right: -20px !important;            
        }
    }   
}
.slick-prev:before {
    content: '' !important;
    background-image: url('../images/structure/next.png');
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.slick-next:before {
    content: '' !important;
    background-image: url('../images/structure/prev.png');
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.slick-next {
    right: auto;
    left: -16px !important;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--gray);
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-prev {
    right: -16px !important;
    left: auto;
    z-index: 1;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--gray);
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: var(--tertiary);
}

// Mui Tree
.MuiTreeView-root {
    height: 100% !important;
}

.MuiTreeItem-content {
    display: flex;
    align-items: center;
    padding: 0px 8px 16px 8px !important;

    .MuiTreeItem-label {
        @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM' !important, );
    }

    .MuiTreeItem-iconContainer {
        color: var(--text);
        align-items: center;
        width: 24px !important;
        height: 24px;
        margin-left: 8px;
        margin-right: 0px !important;
        transform: rotate(90deg);

        svg {
            font-size: 24px !important;
        }
    }

    // &:hover {
    //     background-color: transparent !important;

    //     .MuiTreeItem-label {
    //         color: var(--tertiary);
    //     }

    //     .MuiTreeItem-iconContainer {
    //         color: var(--tertiary);
    //     }
    // }

    &.Mui-selected {
        background-color: transparent !important;
    }

    &.Mui-expanded {
        .MuiTreeItem-label {
            color: var(--tertiary);
        }

        .MuiTreeItem-iconContainer {
            color: var(--tertiary);
            transform: rotate(-180deg);
        }
    }
}
.MuiTabs-vertical{
    border: none !important;
    .MuiTabs-indicator{
        background-color: var(--primary) !important;
    }
    .MuiTab-textColorPrimary {
        min-width: 100% !important;
        padding: 0px 16px 0px 0px;
        &.Mui-selected{
            color: var(--primary) !important;
        }
    }
}
.MuiTabs-flexContainerVertical{
    .MuiTab-textColorPrimary{
        align-items: flex-start;
    }
}
// checkbox
.MuiCheckbox-colorPrimary {
    padding: 0px !important;

    &.Mui-checked {
        color: #1EA896 !important;
    }
}
// Mui Modal Dialog
.main-modal {
    .h3 {
        @include h3-heading($text, $family: 'SFPRODISPLAYBOLD', );
        margin: 0 0 4px 0;
    }
    .h4 {
        @include h4-heading($text, $family: 'SFPRODISPLAYBOLD', );
        margin: 0 0 28px 0;
    }

    .p3 {
        @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR', );
        margin: 0 0 32px 0;
        &.mb-0{
            margin-bottom: 0px;
        }
        &.mx-2{
            margin: 0px 8px;
        }
    }
    &.theme-dialog {
        .MuiDialog-paper {
            background: $background;
            border-radius: 12px;
            .MuiDialogContent-root {
                max-width: 310px;
                min-width: 310px;
                padding: 20px 24px 24px 24px !important;

                .MuiButton-textPrimary {
                    color: $gray !important;
                }

                .MuiButton-textError {
                    color: $primary !important;
                }
                a{
                    color: $primary !important;
                    text-decoration: none !important;
                }
            }
        }
    }
}
.maxwidth-765{
    .MuiDialog-paperWidthSm {
        width: 100% !important;
        max-width: 765px !important;
        border-radius: 10px !important;
    }
}
.maxwidth-576 {
    .MuiDialog-paperWidthSm {
        width: 100% !important;
        max-width: 576px !important;
        border-radius: 10px !important;
    }
}
.maxwidth-430{
    .MuiDialog-paperWidthSm {
        width: 100% !important;
        max-width: 430px !important;
        border-radius: 10px !important;
    }   
    .h6{
        font-size: 14px;
        margin-bottom: 10px;
        color: $text;
    }
    .background-box{
        background: $background_light;
        padding: 4px 16px;
        border-radius: 12px;
    }
}
.modal-close {
    width: 24px;
    height: 24px;
    object-fit: contain;
    position: absolute;
    left: 16px;
    right: auto;
    top: 16px;
    opacity: 0.6;
    cursor: pointer;
}
// switch btn
.MuiSwitch-sizeMedium{
    width: 40px !important;
    height: 20px !important;
    padding: 0px !important;
}
.MuiSwitch-colorPrimary{
    padding: 0 !important;
    &.Mui-checked{
        color: var(--secondary) !important;
    }
}
.MuiSwitch-thumb{
    padding: 0 !important;
    box-shadow: none !important;
}
.MuiSwitch-track{
    border-radius: 20px !important;

}
.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: var(--secondary) !important;
    opacity: 0.5;
}


// switch btn
.MuiSwitch-sizeMedium {
    width: 40px !important;
    height: 20px !important;
    padding: 0px !important;
}

.MuiSwitch-colorPrimary {
    padding: 0 !important;

    &.Mui-checked {
        color: var(--secondary) !important;
    }
}

.MuiSwitch-track {
    border-radius: 20px !important;

}

.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: var(--secondary) !important;
    opacity: 0.5;
}


// starting vinay css MUI
//MuiInputAdornment-positionEnd
.MuiInputAdornment-positionEnd.bg-pink {
    height: 100%;
    background: $background_light;
    position: absolute;
    padding: 10px 16px;
    top: 1px;
    bottom: auto;
    border-radius: 8px 0px 0px 8px;
    margin-left: 0px;
    min-height: 54px;
    right: auto;
    left: 1px;
    border-right: 1px solid $gray;
    z-index: 1;
    .MuiTypography-root {
        font-size: 14px;
        color: $text;
    }
    .MuiSelect-select{
        padding: 0px  0px 0px 20px !important;
    }
    .MuiOutlinedInput-notchedOutline{
        border: none !important;
    }
    .MuiSelect-iconOutlined {
        left: -8px !important;
        right: auto;
    }
}

// required-star
.required-star {
    color: $primary;
}

// chips custome
.select-chips {
    background: $background !important;
    border-radius: 0.5rem !important;
    margin-top: 12px !important;
    margin-left: 16px !important;

    .MuiChip-deleteIcon {
        color: $primary !important;
        margin: 0 -6px  0 5px!important;
    }
}
.MuiChip-deleteIcon {
    color: $primary !important;
    margin: 0 -6px  0 5px!important;
}
.min-width148 {
    min-width: 148px !important;
}

.MuiPaper-root {
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
    .MuiAlert-icon {
        margin-left: 12px !important;
        margin-right: 0px !important;
    }

    .MuiAlert-action {
        padding: 4px 16px 0px 0px !important;
        margin-left: -8px !important;
        margin-right: auto !important;
    }
}

.no-event {
    pointer-events: none;
    cursor: not-allowed
}

.p-float-label {
    &>label {
        left: auto;
        right: 0.75rem;
        color: $gray !important;
    }

    .p-inputwrapper-filled~label,
    .p-inputwrapper-focus~label {
        left: auto;
        right: 0.75rem;
        top: 0.25rem;
        font-size: 12px;
        width: auto;
        background: white;
        padding: 0 4px;
    }

    .p-dropdown {
        width: 100%;
        min-height: 56px;
        border-color: var(--gray) !important;
        border-radius: 8px !important;

        &:focus,
        &.p-focus {
            border-width: 2px;
            border-color: var(--tertiary) !important;
            box-shadow: none !important;
            outline: none !important;
        }

        .p-dropdown-trigger {
            width: 2rem;

            .p-dropdown-trigger-icon {
                border: 3px solid transparent;
                border-bottom: 3px solid;
                border-left: 3px solid;
                transform: rotate(-45deg) scale(1.18);

            }
        }
    }
}

.p-dropdown-header {
    padding: 0px 0px !important;

    .p-inputtext {
        border-color: var(--gray);
        box-shadow: none !important;
        outline: none !important;
        padding-right: 3rem !important;
        margin-right: 0 !important;

        &:hover,
        &:focus {
            border-color: $tertiary;
        }
    }
}
.p-dropdown.onShowDrop{
    padding: 0.75rem 0.75rem;
    position: relative;
    line-height: 28px;
    text-align: right;
    &::after {
        content: '';
        position: absolute;
        top: 22px;
        left: 13px;
        border: 3px solid transparent;
        border-bottom: 3px solid #6c757d;
        border-left: 3px solid #6c757d;
        transform: rotate(-45deg) scale(1.18);
    }
    &~label {
        left: auto;
        right: 0.75rem;
        top: 0.25rem;
        font-size: 12px;
        width: auto;
        background: white;
        padding: 0 4px;
    }
}
.p-dropdown-items-wrapper {
    background: $background_light;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
    .p-dropdown-item {
        padding: 8px 20px !important;

        &.p-highlight,
        &:hover {
            background: $background !important;
            color: $text !important;
        }
    }
}

// End vinay css MUI

// Mui table
.MuiTableContainer-root{
    box-shadow: none !important;
    border-radius: 8px 8px 0px 0px !important;
    color: var(--text) !important;
}
.MuiTableRow-head{
    background-color: var(--background);
    color: var(--text);
    .MuiTableCell-head{
        background-color: var(--background);
        @include p3-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
        border-bottom: none !important;
        line-height: 16px;
        text-align: right;
        @media (max-width: 889px) {
            white-space: nowrap;
        }
        .short-div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            line-height: 16px;
            .short-box {
                display: flex;
                flex-direction: column;
                margin-right: 4px;
                svg {
                    opacity: 1;
                    height: 12px;
                    width: 12px;
                    path {
                        transform: scale(1.85);
                        transform-origin: center;
                        color: $tertiary;
                    }
                    &:hover {
                        opacity: 0.5;
                        cursor: pointer;
                    }
                    &[data-testid="ArrowDropUpIcon"] {
                        position: relative;
                        bottom: -3px;
                    }
                    &[data-testid="ArrowDropDownIcon"] {
                        position: relative;
                        top: -3px;
                    }
                }
            }
        }
        &.MuiTableCell-alignCenter {
            .short-div {
                justify-content: center;
            }
        }
    }
}
.MuiTableBody-root{
    .MuiTableCell-body{
        @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
        border-color: #eeeeee !important;
        text-align: right;
        .icon20{
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin: 0px 8px;
            // &+.icon20{
            //     margin: 0px 20px;
            // }
        }  
        .line-clamp2{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 290px;
        }      
    }
    .MuiTableRow-root{
        &.row-grayOut{
            .MuiTableCell-body{
                background: $tab;
                color: $gray;
            }
        }
    }
}
// menu dropdown
.p2-drop-menu{
    @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
    margin-left: 8px;
    margin-right: 8px;
}
.language-block{
    .MuiButton-root{
        padding: 0 !important;
    }
}
.MuiMenu-paper {
    background-color: var(--white) !important;
    max-height: 240px !important; 
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16) !important;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
    .MuiList-padding {
        padding: 0 !important;
    }
    .MuiMenuItem-root {
        .nav-dropdown-content {
            @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
            display: flex;
            width: 100%;
            .language-img{
                margin-left: 8px;
            }
        }
        &:hover,&.Mui-selected,&.active {
            background-color: rgba(255, 238, 218, 1) !important;
        }
    }
}

// MuiRadio-root
.MuiRadio-root{
    &.Mui-checked{
        color: $secondary !important;
    }
}
// clock picker
.MuiPickersPopper-root{
    .MuiPickersArrowSwitcher-root{
        width: 62px;
    }
    .MuiClock-squareMask{
        background-color: $background;
    }
    .MuiClock-pin,.MuiClockPointer-root,.MuiClockPointer-thumb{
        background-color: $primary;
    }
    .MuiClockPointer-thumb{
        border-color: $primary;
    }
    .MuiIconButton-root{
        background-color: transparent;
        color: $text;
        &:hover{
        background-color: $background;
        }
        &:focus,&:active{
            background-color: $primary;
            color: $white;
        }
    }    
}
// date picker
.MuiCalendarOrClockPicker-root{
    .MuiButtonBase-root,button{
        &.Mui-selected{
            background-color: $primary !important;
        }
    }
}
// MuiInputAdornment
.MuiInputAdornment-positionEnd.bg-theme{
    position: absolute;
    min-height: 56px;
    min-width: 60px;
    background-color: $background_light;
    left: 0px;
    margin-left: 0px !important;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    justify-content: center;
    text-align: center;
    border-right: 1px solid $gray;
    .MuiTypography-root{
        @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR', );
    }
}
// clock-picker
.clock-picker{
    position: relative;
    &.mb-3{
        margin-bottom: 1rem !important;
    }
    .MuiInputBase-root{
        width: 100%;
    }
    .MuiInputLabel-root{
        position: absolute;
        transform: translate(-12px, -8px) scale(0.75) !important;
        background: #fff;
        color: #A9ACAA !important;
        z-index: 9;
        padding: 0 3px;
        font-family: 'SFPRODISPLAYREGULAR';
    }
    .MuiSelect-iconOutlined{
        right: auto !important;
        left: 10px !important;
        @media (max-width: 767px){
            left: 10px !important;
        }
    }
    .Mui-disabled {
        &::after{
            right: auto !important;
            left: 10px !important;
        }
    }
    .MuiOutlinedInput-input{
        padding-right: 14px !important;
        @media (max-width: 767px){
            font-size: 12px !important;
        }
    }
    .am-pm-drop{
        position: absolute;
        top: 2px;
        right: auto;
        left: 2px;
        height: 52px;
        width: 44px;
        background-color: transparent !important;
        border-radius: 8px;
        &::before{
            content: '';
            position: absolute;
            left: auto;
            right: 2px;
            top: 0px;
            bottom: 0px;
            height: 20px;
            width: 1px;
            background: #d5beb2;
            margin: auto;
        }
        &.Mui-disabled {
            &::after{
                display: none !important;
            }
        }
        .MuiSelect-select{
            padding: 14px 10px !important;
            @media(max-width: 767px) {
                padding: 14px 6px !important;
            }
        }
        .MuiSelect-iconOutlined{
            display: none !important;
        }
        .MuiOutlinedInput-notchedOutline{
            border: none !important;
        }
        @media(max-width: 767px) {
            width: 33px;
        }
    }
}
#menu-AM-PM{
    .MuiMenu-paper{
        min-width: auto !important;
    }
}
.filter-search{
    .searches-block{
        .searches-icon{
            top: 6px;
        }
    }
    .searches-input {
        .MuiOutlinedInput-input{
            padding: 6.5px 48px 6.5px 48px;
        }
    }
}
.chips{
    @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM', );
    &.chips-approved{
        color: $secondary;
    }
    &.chips-pending{
        color: $tertiary;
    }
}
.chips-fill{
    @include p3-paragraph($text, $family: 'SFPRODISPLAYMEDIUM', );
    padding: 4px 8px 5px 8px;
    min-width: 80px;
    display: inline-block;
    border-radius: 4px;
    text-align: center;
    &.gray{
        background: $line;
    }
}
.MuiSnackbar-root{
    z-index: 999999 !important;
}
// datePicker mobile
.datepicker-icon{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        top: 0px;
        right: auto;
        bottom: 0px;
        left: 10px;
        margin: auto;
        width: 24px;
        height: 24px;
        background: url('../../assets/images/structure/Calendar.svg') no-repeat center center;
        background-size: contain;
    }
}
.MuiPickersCalendarHeader-root{
    direction: ltr;
}
.MuiPickersToolbar-root.MuiDatePickerToolbar-root{
    display: none;
}
.MuiDialogActions-root.MuiDialogActions-spacing{
    button{
        color: $primary !important;
    }
}
.ck-editor{
    direction: rtl;
    .ck-editor__editable_inline{
        text-align: right !important; 
        min-height: 90px;
    }
    .ck-placeholder{
        @include p2-paragraph($gray, $family: 'SFPRODISPLAYREGULAR', );
        &::before{
            color: $gray !important;
        }
    }
}
.MuiTabScrollButton-root{
    transform: rotate(180deg);
    width: 20px !important;
    display: block !important;
    top: -2px;
    &.Mui-disabled{
        opacity: 1 !important;
        pointer-events: all !important;
    }
}
.delete-modal-footer{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .MuiButtonBase-root.MuiButton-root{
        max-width: 80px;
        margin-left: 16px;
    }
}
.MuiTableCell-alignCenter{
    text-align: center !important;  
}