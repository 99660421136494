@import '../../../styles.scss';

.sidebar-wrapper {
    width: 250px;
    margin-top: 70px;
    transition: ease-in-out 0.3s;   
    @media (max-width: 600px) {
        margin-top: 62px;
    }
    .show-arrow{
        position: absolute;
        top: 0px;
        left: -24px;
        height: 30px;
        width: 24px;
        background-color: var(--background_light);
        box-shadow: -4px 1px 4px 0px rgb(0 0 0 / 16%);
        cursor: pointer;
        display: none;
        justify-content: center;
        align-items: center;
        border-radius: 10px 0px 0px 10px;
        img.show-arrow-icon{
            object-fit: scale-down;
            width: 14px;
            height: 14px;
            transform: rotate(180deg);
        }

    }
    @media (max-width: 899px) {
        position: fixed;
        right: -254px;
        top: 0px;
        bottom: 0px;
        box-shadow: 0px 10px 10px 0px #00000060;
        background: var(--background_light);
        z-index: 99;
        .show-arrow{
            display: flex;
        }
        &.show{
            right: 0px;
            .show-arrow{
                img.show-arrow-icon{
                    transform: rotate(0deg);
                }
            }
        }
    }
    .sidebar-block {
        padding-top: 30px;
        margin: 0px 12px;
        position: sticky;
        top: 70px;
        max-height: calc(100vh - 70px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-color: transparent transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            background-color: transparent;
            border-radius: 0px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border-radius: 0px;
        }

        &::-webkit-scrollbar-track {
            border: 0.0625rem solid transparent;
            border-radius: 0px;
        }
        .sidebar-links {
            display: flex;
            align-items: center;
            padding: 15px 16px;
            cursor: pointer;
            margin-bottom: 6px;
            text-decoration: none;
            border-radius: 28px;
            width: 220px;
            position: relative;
            .sidebar-icon {
                width: 24px;
                height: 24px;
            }
            .p2 {
                @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                margin: 0px 10px;
            }
            &.active,
            &:focus,
            &:active,
            &:hover {
                background-color: var(--background);
                .p2 {
                    font-family: 'SFPRODISPLAYMEDIUM';
                }
            }
            .notification-signal{
                width: 8px;
                min-width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--primary);
                display: flex;
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 16px;
                margin: auto;
                display: flex;
            }
            &:nth-child(3),&:nth-child(5){
                .notification-signal{
                    display: flex;
                }
            }
        }
    }
}
.ml-auto{
    margin-left: 0px;
    margin-right: auto;
}