@import "../../styles.scss";
.order-detail-wrapper{
    .card-white{
        padding: 20px 20px 20px 20px;
        margin-bottom: 20px;
    }
    .store-detail-head{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
            margin: 0 0px 0 12px;
        }
        .p2{
            @include p2-paragraph($tertiary, $family: 'SFPRODISPLAYMEDIUM',);
            margin: 0 30px 0 0px;
            &.p2-tertiary{
                color: var(--tertiary);
            }
            &.p2-secondary{
                color: var(--secondary);
            }
            &.p2-primary{
                color: var(--primary);
            }
        }
    }
    .store-bottom-head-block{
        display:  flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid var(--line);
        padding-top: 12px;
        margin-top: 16px;
        .block-boxes{
            width: 200px;
            border-right: 1px solid var(--line);
            padding-right: 20px;
            &:first-child{
                border-right: none;
                padding-right: 0px;
            }
            .p3{
                @include p3-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 4px 0;
            }
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 0 0;
            }
        }
        .download-box{
            .p2{
                @include p2-paragraph($primary, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 8px 0 0;
                cursor: pointer;
            }
        }
    }
    .order-product-detail-wrap{
        .p3{
            @include p3-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 16px 0;
        }
        .product-blocks{
            display:  flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .product-blocks-img{
                width: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 8px;
            }
            .product-blocks-content{
                padding-right: 8px;
                .p2{
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                    margin: 0 0 8px 0;
                }
                .p3{
                    @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                    margin: 0 0 0px 0;
                }
            }
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 0px 0;
            }
        }
    }
    .order-shipping-detail-wrap{
        .p3{
            @include p3-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 16px 0;
        }
        .shipping-blocks{
            .h6{
                @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 12px 0;
            }
            .p3{
                @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                margin: 0 8px 0px 0px;
            }
        }
    }
    .order-order-tracking-wrap{
        .p3{
            @include p3-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 16px 0;
        }
        .order-tracking-block{
            .order-tracking-list{
                display:  flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                &:last-child{
                    .dot-circle{
                        &::after{
                            display: none;
                        } 
                    }
                }
                &.success{
                    .dot-circle{
                        border-color: var(--secondary);
                        background-color: var(--secondary);
                        &::after{
                            content: '';
                            width: 1px;
                            height: 24px;
                            background-color: var(--secondary);
                            margin-right: 4px;
                            margin-top: 11px;
                        }
                    }
                }
                &.finish{
                    .dot-circle{
                        border-color: var(--secondary);
                        background-color: var(--secondary);
                    }
                }
                .dot-circle{
                    display: flex;
                    width: 12px;
                    min-width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: var(--white);
                    border: 1px solid var(--line);
                    position: absolute;
                    z-index: 1;
                    &::after{
                        content: '';
                        width: 1px;
                        height: 24px;
                        background-color: var(--line);
                        margin-right: 4px;
                        margin-top: 11px;
                    }
                    &.reject-cancel{
                        border-color: var(--primary);
                        background-color: var(--primary);
                    }
                }
                .p2{
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                    margin: 0 24px 0 0;
                }
                .p3{
                    @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                    margin: 0 0 0 0;
                }
            }
        }
    }
    .order-payment-details-wrap{
        .p3{
            @include p3-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 16px 0;
        }
        .payment-lists{
            display:  flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                margin: 0 0 0px 0;
            }
            .p2-bold{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 0px 0;
            }
        }
        .payment-lists-total{
            display:  flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid var(--line);
            padding-top: 16px;
            margin-top: 16px;
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 0px 0;
            }
        }
    }
}