@import "../../styles.scss";
.order-listing-wrapper{
    .store-detail-head{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
            margin: 0 0px 0 12px;
            @media (max-width: 599px) {
                margin: 0 0px 16px 0px;
            }
        }
    }
    .total-order-text{
        @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        margin: auto;
        display: flex;
        align-items: center;

    }
}

.info-box {
    cursor: pointer;
    position: relative;

    .info-icon {
        cursor: pointer;
        position: relative;
        bottom: -2px;
        max-width: 18px;
        margin-left: 5px;
    }

    .info-content {
        padding: 15px 20px;
        border-radius: 0.5rem;
        background: $white;
        min-width: 250px;
        position: absolute;
        transform: translateY(-10px);
        visibility: hidden;
        opacity: 0;
        transition: all 100ms;
        z-index: 10;
        @media(max-width: 767px) {
            right: -30px;
        }
        .p3 {
            @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');

            &.bold {
                font-family: 'SFPRODISPLAYMEDIUM';
            }
        }
    }

    &:hover {
        .info-content {
            transform: translateY(0px);
            visibility: visible;
            opacity: 1;
        }
    }
}