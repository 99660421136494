@import "../../../styles.scss";
.signup-wrapper{
    .signup-block{
        background-color: var(--background_light);
        border-radius: 10px;
        padding: 40px 64px;
        position: relative;
        @media (max-width: 1024px) {
            padding: 30px 15px;
        }
        &::before{
            content: '';
            background-image: url('../../../assets/images/structure/sec3-top-mask.png');
            width: 609px;
            height: 300px;
            position: absolute;
            left: 0;
            top: 0;
            @media (max-width: 1024px) {
                width: 100%;
                max-width: 609px;
            }
        }
        &::after{
            content: '';
            background-image: url('../../../assets/images/structure/sec3-bottom-mask.png');
            width: 609px;
            height: 300px;
            position: absolute;
            right: 0;
            bottom: 0;
            @media (max-width: 1024px) {                
                width: 100%;
                max-width: 609px;
            }
        }
        .signup-left{
            margin-top: 70px;
            @media (max-width: 1024px) {
                margin-top: 0;
            }
            .h1{
                @include h1-heading($text, $family: 'SFPRODISPLAYBOLD');
                margin: 0 0 24px 0;
            }
            .h3{
                @include h3-heading($text, $family: 'SFPRODISPLAYREGULAR');
                margin: 0 0 24px 0;
                font-weight: 400;
            }
        }
        .signup-right{
            .white-box{
                background-color: var(--white);
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                position: relative;
                z-index: 1;
                padding: 30px 30px;
                @media (max-width: 1024px) {
                    padding: 30px 15px;
                }
            }
            .h3{
                @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 20px 0;
            }
            .p1{
                @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 8px 0;
            }
            .p3{
                @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                margin: 0 0 30px 0;
                &.mb-1{
                    margin-bottom: 4px;
                }
            }
            .img-upload-file{
                background-color: var(--tab);
                border-radius: 8px;
                padding: 24px 2px;
                position: relative;
                display: flex;
                margin-bottom: 30px;
                input[type="file"]{
                    position: absolute;
                    top: -9999999;
                    filter: alpha(opacity=0);
                    opacity: 0;
                    width:0;
                    height:0;
                    outline: none;
                    cursor: pointer;
                }
                .p2{
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                    margin: 0 0 4px 0;
                }
                .p3{
                    @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                    margin: 0 0 0px 0;
                }
                .upload-icon{
                    width: 32px;
                    height: 32px;
                    margin-left: 24px;
                    margin-right: 24px;
                    @media (max-width: 599px) {                        
                        margin-left: 12px;
                        margin-right: 12px;
                    }
                }
            }
            .uploaded-image-box{
                background-color: $white;
                border: 1px solid #E8E8E8;
                border-radius: 8px;
                padding: 0px;
                width: 80px;
                height: 80px;
                position: relative;
                margin-bottom: 30px;
                overflow: hidden;
                .store-logo{
                    width: 80px;
                    height: 80px;
                    border-radius: 8px;
                }
                .cancel-red{
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    cursor: pointer;
                }
            }
            .uploaded-text-block{
                background-color: var(--background);
                border-radius: 8px;
                padding: 6px 12px 6px 40px;
                width: 324px;
                position: relative;
                margin-bottom: 12px;
                &:last-child{
                    margin-bottom: 30px;
                }
                .p2{
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                }
                .close-icon{
                    position: absolute;
                    left: 12px;
                    cursor: pointer;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .checkbox-block{
                display: flex;
                align-items: center;
                .p2{
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                }
                .p2-semibold{
                    @include p2-paragraph($primary, $family: 'SFPRODISPLAYMEDIUM');
                    margin: 0 10px 0 4px;
                }
            }
        }
    }
}