@import "../../styles.scss";
.store-detail-wrapper{
    .store-detail-head{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYBOLD',);
            margin: 0 12px 0 12px;    
            display: flex;        
        }
    }
    .store-status{
        max-width: 165px;
    }
    .business-detail-wrap{
        .h6{
            @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 8px 0;
        }
        .p3{
            @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
            margin: 0 0 32px 0;
        }
    }
    .signup-right{
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 20px 0;
        }
        .p1{
            @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 8px 0;
            &.mb-0{
                margin: 0px 0px 0px 0px;
            }
        }
        .p3{
            @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
            margin: 0 0 30px 0;
        }
        .img-upload-file{
            background-color: var(--tab);
            border-radius: 8px;
            padding: 24px 2px;
            position: relative;
            display: flex;
            margin-bottom: 30px;
            input[type="file"]{
                position: absolute;
                top: -9999999;
                filter: alpha(opacity=0);
                opacity: 0;
                width:0;
                height:0;
                outline: none;
                cursor: pointer;
            }
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 4px 0;
            }
            .p3{
                @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                margin: 0 0 0px 0;
            }
            .upload-icon{
                width: 32px;
                height: 32px;
                margin-left: 24px;
                margin-right: 24px;
                @media (max-width: 599px) {                        
                    margin-left: 12px;
                    margin-right: 12px;
                }
            }
        }
        .uploaded-image-box{
            background-color: $white;
            border: 1px solid #E8E8E8;
            border-radius: 8px;
            padding: 0px;
            width: 80px;
            height: 80px;
            position: relative;
            margin-bottom: 30px;
            overflow: hidden;
            .cancel-red{
                position: absolute;
                right: 0px;
                top: 0px;
                cursor: pointer;
            }
        }
        .uploaded-text-block{
            background-color: var(--background);
            border-radius: 8px;
            padding: 6px 12px 6px 40px;
            width: 324px;
            position: relative;
            margin-bottom: 12px;
            &:last-child{
                margin-bottom: 30px;
            }
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
            }
            .close-icon{
                position: absolute;
                left: 12px;
                cursor: pointer;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            @media(max-width: 767px) {
                width: 100%;
            }
        }
        .checkbox-block{
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                margin-bottom: 16px;
            }
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                margin: 0 10px 0 4px;
            }
            .p2-semibold{
                @include p2-paragraph($primary, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 10px 0 4px;
            }
        }
    }
    .my-stores-wrap{
        background: var(--white);
        border-bottom: 1px solid var(--line);
        border-radius: 8px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        @media (max-width: 899px) {
            overflow: auto;
        }
        &.row-grayOut{
            background: var(--line); 
        }
        .img-block{
            background: var(--white);
            border-bottom: 1px solid var(--line);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            height: 40px;
            width: 40px;
            overflow: hidden;
            .logo-icon{
                width: 40px;
                min-width: 40px;
                height: 40px;
                object-fit: scale-down;
            }
        }
        .first-div-logo{
            min-width: 25%;
            @media (max-width: 899px) {
                 min-width: 200px;
            }
        }
        .content-block{
            padding-right: 16px;
            .p1{
                @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0;
                max-width: 197px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .address-block{
            width: 30%;
            @include p1-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
            margin: 0;
            @media (max-width: 899px) {
                 min-width: 200px;
            }
        }
        .p2{
            @include p2-paragraph($tertiary, $family: 'SFPRODISPLAYREGULAR');
            margin: 0;
        }
        .gray-chips{
            background: #EEEEEE;
            color: $text;
            padding: 4px 12px 5px 12px;
            border-radius: 4px;
            font-size: 12px;
            margin-right: auto;
        }
    }
    
    
    
}