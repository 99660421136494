@import "../../styles.scss";
.store-detail-wrapper{
    .store-detail-head{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
            margin: 0 0px 0 12px;  
            @media (max-width: 899px) {
                margin: 0 0px 10px 0px;
            }
        }
    }
    .store-detail-tabs-block{
        .card-white{
            padding: 20px 0px 86px 20px;
            @media (max-width: 1199px) {
                padding: 20px 0px 20px 20px;
                margin-bottom: 20px;
            }
        }
    }
    .business-detail-wrap{
        .h6{
            @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 8px 0;
        }
        .p3{
            @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
            margin: 0 0 32px 0;
        }
    }
    .signup-right{
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 20px 0;
        }
        .p1{
            @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 8px 0;
        }
        .p3{
            @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
            margin: 0 0 30px 0;
            &.mb-0{
                margin-bottom: 0px;
            }
        }
        .img-upload-file{
            background-color: var(--tab);
            border-radius: 8px;
            padding: 24px 2px;
            position: relative;
            display: flex;
            margin-bottom: 30px;
            input[type="file"]{
                position: absolute;
                top: -9999999;
                filter: alpha(opacity=0);
                opacity: 0;
                width:0;
                height:0;
                outline: none;
                cursor: pointer;
            }
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
                margin: 0 0 4px 0;
            }
            .p3{
                @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                margin: 0 0 0px 0;
            }
            .upload-icon{
                width: 32px;
                height: 32px;
                margin-left: 24px;
                margin-right: 24px;                
                @media (max-width: 599px) {                        
                    margin-left: 12px;
                    margin-right: 12px;
                }
            }
        }
        .uploaded-image-box{
            background-color: $white;
            border: 1px solid #E8E8E8;
            border-radius: 8px;
            padding: 0px;
            width: 80px;
            height: 80px;
            position: relative;
            overflow: hidden;
            margin-bottom: 30px;
            .store-logo{                
                width: 80px;
                height: 80px;
            }
            .cancel-red{
                position: absolute;
                right: 0px;
                top: 0px;
                cursor: pointer;
            }
        }
        .uploaded-text-block{
            background-color: var(--background);
            border-radius: 8px;
            padding: 6px 12px 6px 40px;
            width: 324px;
            position: relative;
            margin-bottom: 12px;
            &:last-child{
                margin-bottom: 30px;
            }
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
            }
            .close-icon{
                position: absolute;
                left: 12px;
                cursor: pointer;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
        .checkbox-block{
            display: flex;
            align-items: center;
            .p2{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
                margin: 0 10px 0 4px;
            }
            
        }
    }
    .date-picker-btn{
        margin-right: 12px;
    }
}
.empty-header-row{
    .h6{
        @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
        margin: 0 0 8px 0 !important;
    }
    .p2{
        @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
        margin: 0 0 0px 0;
    }
    .MuiButtonBase-root {
        text-transform: capitalize;
        font-family: 'SFPRODISPLAYREGULAR';

    }
}
.empty-box{
    padding: 40px 0px;
    min-height: auto !important;
}
.width220{
    width: 220px !important;
    margin-bottom: 20px;
}
.aroow-hide-disabled{    
    &.Mui-disabled{
        &::after{
            display: none;
        }
    }
}

.disable-hours {
    pointer-events: none;
    opacity: 0.5;
}