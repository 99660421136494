@import "../../styles.scss";
.term-condition-wrapper{
    .term-condition-head{
        background-color: var(--background);
        padding: 36px 36px;
        text-align: center;
        margin: 0 0 40px 0;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 0 0;
        }
    }
    .h3{
        @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
        margin: 0 0 20px 0;
    }
    .p1{
        @include p1-paragraph($color: #717972, $family: 'SFPRODISPLAYREGULAR');
        margin: 0 0 50px 0;
    }
}