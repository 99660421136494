.show-hide-div{
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 56px;
    padding-left: 0px;
    background: #ffffff;
    width: 100%;
    max-height: 140px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 99;
    li{
        list-style: none;
        padding: 5px 10px;
        cursor: pointer;
        &:hover{
            background: #f7faf8;
            color: #252525;
        }
    }
}