@import "../../../styles.scss";

.add-product-wrapper {
    .add-product-head {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &.uom-head{
            justify-content: space-between;
            width: 100%;
            margin-top: 10px;
        }

        .h3 {
            @include h3-heading($text, $family: 'SFPRODISPLAYBOLD', );
            margin: 0 12px 0 0;
            &.mx-0{
                margin: 0px 0px 0 0 !important;
            }
        }

        .icon24 {
            width: 24px;
            height: 24px;
            object-fit: contain;
            transform: scaleX(-1);
        }
    }

    .card-white {
        margin-bottom: 20px;

        .h6 {
            @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM', );
            margin: 0 0 30px 0;

            &.mb-0 {
                margin-bottom: 0px;
            }
        }

        .switch-box {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 32px;

            .h6 {
                margin: 0 0px 5px 28px;
            }
        }

        .p2 {
            @include p3-paragraph($text);
            margin-bottom: 20px;
        }

        .uploads-row {
            display: flex;
            flex-wrap: wrap;

            .upload-multiple {
                width: 140px;
                height: 140px;
                position: relative;
                border-radius: 0.5rem;
                background: $tab;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-left: 16px;
                overflow: hidden;
                cursor: pointer;
                @media (max-width: 899px) {
                    width: 130px;
                    height: 130px;
                    margin-left: 10px;
                }
                @media (max-width: 599px) {
                    width: 120px;
                }
                .ant-upload-wrapper{
                    width: 100%;
                    height: 100%;
                    .ant-upload{
                        width: 100%;
                        height: 100%;
                        .ant-upload{
                            flex-direction: column;
                        }
                    }
                }

                .delete-icon {
                    color: #E8141B;
                    fill: #E8141B;
                    position: absolute;
                    right: auto;
                    left: 6px;
                    top: 5px;
                    cursor: pointer;
                    z-index: 2;
                }

                .id-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .upload-icon {
                    width: 32px;
                    height: 32px;
                    object-fit: contain;
                    margin: 0px;
                }

                .upload-text {
                    @include p3-paragraph($text, $family: 'SFPRODISPLAYMEDIUM', );
                    margin-top: 8px;
                    margin-bottom: 0px;
                }
                .required-text{
                    @include p4-paragraph($text, $family: 'SFPRODISPLAYREGULAR', );
                    max-width: 65%;
                    position: relative;
                    margin-bottom: -20px;
                    margin-top: -2px;
                    line-height: 13px;
                }

                input[type=file] {
                    position: absolute;
                    z-index: 1;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    opacity: 0;
                }

                .progress-img{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    position: absolute;
                    width: calc(100% - 0px);
                    margin: auto;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    background-color: rgba($color: $white, $alpha: .75);
                    .progress-count{
                        color: $text;
                        font-size: 24px;
                        font-family: 'SFPRODISPLAYMEDIUM';
                        display: block;
                        margin-bottom: 8px;
                    }
                    .progress-box{
                        height: 6px;
                        width: calc(100% - 10px);
                        border-radius: 6px;
                        overflow: hidden;
                        background: $background;
                        margin: 3px auto;
                        position: relative;
                        .progress-content{
                            position: absolute;                            
                            left: 0px;
                            right: auto;
                            height: 6px;
                            width: auto;
                            background: $primary;
                            border-radius: 6px;
                        }
                    }
                }
            }

            .p2 {
                @include p3-paragraph($text, $family: 'SFPRODISPLAYBOLD', );
            }
        }
        &.uom-empty{
            padding-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .empty-img{
                margin-bottom: 24px;
            }
        }
    }
    .bg-white{
        background-color: $white;
        border-radius: 8px;
        padding-top: 20px;
        margin-bottom: 40px;
        .MuiTableContainer-root{
            border-radius: 0px 0px 0px 0px !important;
        }
        .h6{
            @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM', );
            margin: 0px 16px 20px 16px;
        }
    }
    .btn-row {
        display: flex;
        justify-content: flex-end;
    }
}

.no-event {
    pointer-events: none !important;
    opacity: 0.9 !important;
    cursor: not-allowed !important;
}
.mb-30{
    margin-bottom: 30px;
}

.uom-content{
    max-height: calc(100vh - 150px);
    padding: 10px 0px 60px 0px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;
    @media (max-width: 767px) {
        padding: 10px 0px 90px 0px;
    }
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
    .switch-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 12px;
        margin-bottom: 20px;
        .h6 {
            margin: 0px 0px;
        }
        .MuiSwitch-root {
            margin: 0 28px 0px 28px;
        }
        .p4{
            font-size: 12px;
        }
    }
    .h6{
        @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM', );
        margin: 0px 0px 8px 0px;
    }
    .p3{
        @include p3-paragraph($text, $family: 'SFPRODISPLAYREGULAR', );
        margin: 0px 0px 20px 0px !important;
    }
    .uploads-row {
        display: flex;
        flex-wrap: wrap;    
        &>div{
            width: 106px;
            @media (max-width: 899px) {
                width: 106px;
            }
            @media (max-width: 599px) {
                width: 90px;
            }
        }
        .upload-multiple {
            width: 96px;
            height: 96px;
            position: relative;
            border-radius: 0.5rem;
            background: $tab;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-left: 0px;
            overflow: hidden;
            cursor: pointer;
            @media (max-width: 899px) {
                width: 90px;
                height: 90px;
                margin-left: 0px;
            }
            @media (max-width: 599px) {
                width: 80px;
            }
            .ant-upload-wrapper{
                width: 100%;
                height: 100%;
                .ant-upload{
                    width: 100%;
                    height: 100%;
                    .ant-upload{
                        flex-direction: column;
                    }
                }
            }

            .delete-icon {
                color: #E8141B;
                fill: #E8141B;
                position: absolute;
                right: auto;
                left: 6px;
                top: 5px;
                cursor: pointer;
                z-index: 2;
            }

            .id-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .upload-icon {
                width: 32px;
                height: 32px;
                object-fit: contain;
                margin: 0px;
            }

            .upload-text {
                @include p3-paragraph($text, $family: 'SFPRODISPLAYMEDIUM', );
                margin-top: 8px;
                margin-bottom: 0px;
            }
            .required-text{
                @include p4-paragraph($text, $family: 'SFPRODISPLAYREGULAR', );
                max-width: 65%;
                position: relative;
                margin-bottom: -20px;
                margin-top: -2px;
                line-height: 13px;
            }

            input[type=file] {
                position: absolute;
                z-index: 1;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
            }

            .progress-img{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: absolute;
                width: calc(100% - 0px);
                margin: auto;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                background-color: rgba($color: $white, $alpha: .75);
                .progress-count{
                    color: $text;
                    font-size: 24px;
                    font-family: 'SFPRODISPLAYMEDIUM';
                    display: block;
                    margin-bottom: 8px;
                }
                .progress-box{
                    height: 6px;
                    width: calc(100% - 10px);
                    border-radius: 6px;
                    overflow: hidden;
                    background: $background;
                    margin: 3px auto;
                    position: relative;
                    .progress-content{
                        position: absolute;                            
                        left: 0px;
                        right: auto;
                        height: 6px;
                        width: auto;
                        background: $primary;
                        border-radius: 6px;
                    }
                }
            }
        }

        .p2 {
            @include p3-paragraph($text, $family: 'SFPRODISPLAYBOLD', );
        }
    } 
    .add-on-select-option{   
        .MuiOutlinedInput-root{
            padding-left: 65px !important;
            padding-right: 9px !important;
        }
    }
}
.uom-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 45px);
    background: $white;
    z-index: 2;
    @media (max-width: 575px) {
        flex-direction: column;
    }
    .MuiButtonBase-root.MuiCheckbox-root{
        margin: 0px 8px;
    }
    .min-width140{
        min-width: 140px;
        @media (max-width: 575px) {
            margin-top: 10px;
        }
    }
}

.noEvent {
    pointer-events: none !important;
}

.saveBtn:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}
.pendingUom {
    background-color: #c3c3c3;
    pointer-events: none !important;
}

.MuiAutocomplete-endAdornment{
    right: auto !important;
    left: 6px !important;
}