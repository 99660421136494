@import "../../styles.scss";
.h3{
    margin: 0px 0px 18px 0px;
}
.graph-box{
    padding: 20px;
    border-radius: 8px;
    position: relative;
    background: $white;
    margin-bottom: 50px;
    .graph-top-flex{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0px;
        .h6{
            margin: 0px;
            @include h6-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
        }
        .width115{
            width: 115px !important;
        }
        .width200{
            width: 200px !important;
        }
    }
}