@import "../../styles.scss";

.h3 {
  @include h3-heading($text, $family: "SFPRODISPLAYMEDIUM");
  margin: 0 0 14px 0;
}
.h4 {
  @include h4-heading($text, $family: "SFPRODISPLAYMEDIUM");
  margin: 0 0 14px 0;
}
.card-white {
  margin-bottom: 20px;

  .h6 {
    @include h6-heading($text, $family: "SFPRODISPLAYMEDIUM");
    margin: 36px 0 30px 0;

    &.mb-0 {
      margin-bottom: 0px;
    }
    &.mb-4 {
      margin-bottom: 16px;
    }
  }
}