@import '../src/assets/scss/mixins/fonts';
@import '../src/assets/scss/typography.scss';
@import '../src/assets/scss/color.scss';
@import '../src/assets/scss/mui-material.scss';
@import '../src/assets/scss/prime-react.scss';
@import '../src/assets/scss/baladi-custom.scss';

.container-body{
    width: 1240px;
    margin: auto;
    @media (max-width: 1260px) {
        width: 100%;    
        max-width: 1024px;
    }
}

.css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}