@import "../../styles.scss";
.order-listing-wrapper{
    .store-detail-head{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM',);
            margin: 0 0px 0 12px;
            @media (max-width: 899px) {
                margin: 0 0px 10px 0px !important;
            }
        }
    }
    .searches-block {
        position: relative;
        width: 260px;
        margin-left: 20px;
        .MuiOutlinedInput-input {
            padding: 6.5px 48px 6.5px 12px !important;
        }
        @media (max-width: 899px) {
            width: 100%;
            margin-left: 0px;
        }
    }
    
}
.new-dot{
    position: relative;
    &::after{
        content: '';
        position: absolute;
        left:10px;
        right: auto;
        top: 0px;
        bottom: 0px;
        margin: auto;
        width: 6px;
        height: 6px;
        background: $primary;
        border-radius: 50%;
    }
}
.table-type-parent{
    background: $white;
    border-radius: 8px;
    margin-bottom: 20px;
    &.red-border{
        border: 1px solid $primary;
    }
    .table-type-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 10px 4px;
        max-width: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-color: #f1f1f1 transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: transparent;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #f1f1f1;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-track {
            border: 0.0625rem solid transparent;
            border-radius: 2px;
        }
        @media (max-width: 1360px) {
            max-width: 1360px;
            overflow: auto;
        }
        .table-type-td{
            padding: 0px 12px;
            &.flex{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .type-td{
                    width: 100%;
                    max-width: 240px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; 
                }
            }
            .icon24{
                width: 24px;
                height: 24px;
                object-fit: contain;
            }
            .type-th{
                @include p3-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM',);
                margin: 0;  
                line-height: 14px;
                @media (max-width: 1360px) {
                    white-space: nowrap;
                }
            }
            .type-td{
                @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM',);
                margin: 3px 0px;
                line-height: 16px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                &.width120{
                    width: 120px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: block;
                }
                .icon20{
                    width: 20px;
                    height: 20px;
                    margin: 0px 8px;
                }
                .width220 {
                    width: 220px;
                }
            }
            .relative{
                position: relative;
                padding: 0px 12px;
            }
        }
    }
    .table-type-body{
        border-top: 1px solid $line;
        padding: 20px 20px 0px 20px;
        .table-type-body-inner-flex{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: 1280px) {
                flex-direction: column;
            }
            @media (max-width: 991px) {
                flex-direction: column;
            }
            .h6{
                @include h6-heading($text, $family: 'SFPRODISPLAYBOLD',);
                font-size: 12px !important;
                margin: 0;
                &.mb-4{
                    margin-bottom: 16px;
                }
                &.gray{
                    color: $gray;
                }
            }
            .left-detail-box{
                width: 100%;
                padding-left: 20px;
                padding-right: 0px;
                .product-table{
                    margin-top: 8px;
                    @media (max-width: 767px) {
                        width: 100%;
                        overflow: auto;
                        display: block;
                    }
                    .MuiTableRow-root{
                        &:last-child{
                            .MuiTableCell-root{
                                border-bottom: 0px;
                            }
                        }
                    }
                    .MuiTableCell-root{
                        padding: 8px 0px !important;
                        @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                        @media (max-width: 767px) {
                            white-space: nowrap;
                            padding: 8px 8px !important;
                        }
                        &.MuiTableCell-alignRight{
                            text-align: right !important;
                        }
                        .flex{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            .img32{
                                width: 32px;
                                height: 32px;
                                object-fit: contain;
                                border-radius: 8px;
                            }
                            .product-title{
                                @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                                line-height: 16px;
                                padding: 0px 16px;
                            }
                        }
                        .gray-through{
                            text-decoration: line-through;
                            @include p3-paragraph($gray, $family: 'SFPRODISPLAYREGULAR',);
                        }
                    }
                }
                .order-tracking-box{
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    position: relative;
                    margin-top: 20px;   
                    .order-status{
                        width: 140px;
                        position: relative;
                        padding-top: 12px;
                        &::after{
                            content: '';
                            position: absolute;
                            left: 0px;
                            right: 0px;     
                            top: 0px;
                            bottom: auto;
                            height: 1px;
                            width: 140px;
                            background: $line;
                        }
                        &::before{
                            content: '';
                            position: absolute;
                            left: auto;
                            right: 0px;     
                            top: -6px;
                            bottom: auto;
                            height: 12px;
                            width: 12px;
                            background: $white;
                            border-radius: 50%;
                            border: 1px solid $line;
                            z-index: 5;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                        &.done{
                            &::after{
                                background: $secondary;
                                height: 2px;
                            }
                            &::before{
                                background: $secondary;
                                border: 1px solid $secondary;
                            }
                        }
                        &.active{
                            &::before{
                                background: $secondary;
                                border: 1px solid $secondary;
                            }
                        }
                        p.p2{
                            @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                            margin: 0px 0px 4px 0px;
                        }
                        p.p4{
                            @include p4-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                            margin: 0px;
                        }
                    }
                }
                .MuiRating-root{
                    font-size: 30px;
                    margin: 20px 0px 12px 0px;
                }
                .p2{
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                    margin: 0px;
                }
            }
            .right-detail-box{
                min-width: 290px;
                width: 290px;
                position: relative;
                padding-left: 0px;
                padding-right: 20px;
                padding-bottom: 20px;
                border-right: 1px solid $line;
                @media (max-width: 1280px) {
                    width: 100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-right: 0;
                }
                @media (max-width: 991px) {
                    padding-right: 0px;
                    min-width: 100%;
                    width: 100%;
                    border-right: none;
                    margin-top: 20px;
                }
                .MuiDivider-root{
                    margin: 6px 0px;
                    position: relative;
                    border-color: $line;
                    &.my-5{
                        margin: 20px 0px;
                    }
                }
                .payment-value{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 6px 0px;
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                }
                .payment-value-total{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 0px 15px 0px;
                    .p2{
                        @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM',);
                    }
                }
                .btn-row{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0px -5px;
                    .mx-5{
                        margin: 0px 5px !important;
                    }
                }
                .pink-box{
                    background: $background_light;
                    border-radius: 8px;
                    padding: 12px 16px;
                    margin: 8px 0px 20px 0px;
                    .p2{
                        @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                        margin: 2px 0px;
                    }
                    .p2-bold{
                        @include p2-paragraph($text, $family: 'SFPRODISPLAYMEDIUM',);
                    }
                    .p3{
                        @include p3-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM',);
                        margin: 10px 0px 4px 0px;
                    }
                }
                .mb-4{
                    margin-bottom: 14px;
                }
                .p3{
                    @include p3-paragraph($gray, $family: 'SFPRODISPLAYMEDIUM',);
                    margin: 0px 0px 4px 0px;
                    &.semi{
                        font-family: 'SFPRODISPLAYMEDIUM';
                    }
                }
                .p2{
                    @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',);
                    margin: 0;
                }
            }
        }
    }
}
// red blinking border
.red-border {
    border: 2px solid red;
    // animation: blinker 3s linear infinite;
}

// @keyframes blinker {
//     30% {
//         box-shadow: 0 0 10px red;
//     }
//     50% {
//         box-shadow: 0 0 10px red;
//     }
//     70% {
//         box-shadow: 0 0 10px red;
//     }
//     100% {
//         box-shadow: 0 0 0 red;
//     }
// }
.clear-all {
    padding-left: 15px;
    color: #1EA896;
    cursor: pointer;
}
.mr-20{
    margin-right: 20px;
}
.custom-label{    
    .MuiInputLabel-root{
        transform: translate(1px, -1px) !important;
        margin: 5%;
    }
}