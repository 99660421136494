
@font-face {
    font-family: 'SFPRODISPLAYREGULAR';
    src: url('./../fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
}

@font-face {
    font-family: 'SFPRODISPLAYMEDIUM';
    src: url('./../fonts/SFPRODISPLAYMEDIUM.OTF') format('opentype');
}

@font-face {
    font-family: 'SFPRODISPLAYMEDIUM';
    src: url('./../fonts/SFPRODISPLAYMEDIUM.OTF') format('truetype');
}

@font-face {
    font-family: 'SFPRODISPLAYBOLD';
    src: url('./../fonts/SFPRODISPLAYBOLD.OTF') format('opentype');
}

$h1: 57px !default;
$h2: 45px !default;
$h3: 24px !default;
$h4: 20px !default;
$h5: 18px !default;
$h6: 16px !default;
$p1: 16px !default;
$p2: 14px !default;
$p3: 12px !default;
$p4: 10px !default;

// mobile
$h1-mobile: 45px !default;
$h2-mobile: 36px !default;
$h3-mobile: 20px !default;
$h4-mobile: 18px !default;
$h5-mobile: 16px !default;
$h6-mobile: 14px !default;
$p1-mobile: 14px !default;
$p2-mobile: 12px !default;
$p3-mobile: 10px !default;
$p4-mobile: 10px !default;
