@import "../../../styles.scss";
.manage-workinghours-wrapper{
    padding-top: 70px;
    width: 100%;
    .manage-workinghours-head{
        background-color: var(--background);
        padding: 20px 36px;
        text-align: center;
        margin: 0 0 0px 0;
        height: 155px;
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0px 0 0px 0;
        }
    }
    .working-holiday-block{
        margin-top: -80px;
        .card-white{
            padding: 20px 20px 20px 20px;
            border: 1px solid var(--tab);
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            min-height: calc(100vh - 230px);
            max-height: calc(100vh - 230px);
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            scrollbar-color: #EEEEEE transparent;
            scrollbar-width: thin;
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                background-color: transparent;
                border-radius: 2px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #EEEEEE;
                border-radius: 2px;
            }
            &::-webkit-scrollbar-track {
                border: 0.0625rem solid transparent;
                border-radius: 2px;
            }
            .holiday-head{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 20px;
                .mb-0{
                    margin-bottom: 0px;
                }
                .skip-a{
                    @include p2-paragraph($secondary, $family: 'SFPRODISPLAYREGULAR');
                    text-decoration: none;
                }
            }
            .holiday-empty{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                min-height: 300px;
            }
        }
        .h4{
            @include h4-heading($text, $family: 'SFPRODISPLAYMEDIUM',) ;
            margin: 0 0 8px 0;
        }
        .p2{
            @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR',) ;
            margin: 0 0 20px 0;
        }
    }
    .checkbox-block{
        display: flex;
        align-items: center;
        .p2{
            @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
            margin: 0 10px 0 4px;
        }
        
    }
}

.empty-header-row{
    .h6{
        @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');
        margin: 0 0 8px 0 !important;
    }
    .p2{
        @include p2-paragraph($text, $family: 'SFPRODISPLAYREGULAR');
        margin: 0 0 0px 0;
    }
    .MuiButtonBase-root {
        text-transform: capitalize;
        font-family: 'SFPRODISPLAYREGULAR';
        white-space: nowrap;
        @media (max-width: 599px) {
            padding: 0px 0px;
        }

    }
}
.empty-box{
    padding: 40px 0px;
    min-height: auto !important;
}
.width220{
    width: 220px !important;
    margin-bottom: 20px;
}

.color-gray {
    color: gray; 
}
