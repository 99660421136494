@import "../../styles.scss";
.faq-condition-wrapper{
    padding-top: 30px;
    min-height: calc(100vh - 490px);
    .faq-condition-head{
        background: url('../../assets/images/structure/static-banner.jpg') no-repeat;
        background-size: cover; 
        background-position: 100% 100%;
        width: 100%;
        height: 180px;
        padding: 36px 36px;
        text-align: center;
        margin: 0 0 56px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
            margin: 0 0 36px 0;
        }
        .h3{
            @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
            margin: 0 0 0 0;
        }
    }
    .container-body{
        @media (max-width: 960px) {            
            padding: 0px 15px;
        }
        .faq-condition-body{
            max-width: 1028px;
            margin: auto;
            .p-accordion{
                .p-accordion-tab{                
                    .p-accordion-header{
                        .p-accordion-header-link{
                            background: transparent;
                            border: none;
                            border-top: 1px solid $line;
                            border-radius: 0px;
                            box-shadow: none;
                            outline: none;
                            min-height: 64px;
                            position: relative;
                            @include p1-paragraph($text, $family: 'SFPRODISPLAYMEDIUM');  
                            .p-accordion-header-text{
                                line-height: 20px;
                                margin-left: 20px;
                            }
                            .p-accordion-toggle-icon{
                                display: none;
                            }
                            &::after{
                                content: '';
                                position: absolute;
                                left: 16px;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 24px;
                                height: 24px;
                                background: url('../../assets/images/structure/new-plus-icon.svg') no-repeat;
                                background-size: contain;
                                background-position: center;
                            }
                            &[aria-expanded="true"]{
                                background: $background_light;
                                border-top: none;
                                border-radius: 12px;
                                &::after{
                                    background: url('../../assets/images/structure/new-minus-icon.svg') no-repeat;
                                }
                            }
                        }   
                    }
                    .p-toggleable-content{
                        .p-accordion-content{
                            border: none;
                            .p2{
                                margin: 0px;
                            }
                        }
                    }
                    &:last-child{
                        border-bottom: 1px solid $line;
                    }
                }
            }
        }
    }
    .h3{
        @include h3-heading($text, $family: 'SFPRODISPLAYMEDIUM');
        margin: 0 0 20px 0;
    }
}