
[dir="ltr"]{
    // toolbar scss
    .top-toolbar-wrapper {
        .left-wrap {
            .p2 {
                margin: 0 0px 0 8px;
            }
        }
    }
    .top-toolbar-wrapper {
        .right-wrap {
            .p2-bold {
                margin-right: 0px;
                margin-left: 8px;
            }
        }
    }
    .top-toolbar-wrapper {
        .right-wrap {
            .language-block {
                margin-right: 0px;
                margin-left: 24px;
            }
        }
    }
    // Header scss
    .header-wrapper {
        .right-wrap {
            .header-btn {
                margin-right: 0px !important;
                margin-left: 12px !important;
                @media (max-width: 599px) {
                    margin-right: 0px !important;
                    margin-left: 0px !important;
                }
                .header-btn-icon {
                    margin-left: 0px;
                    margin-right: 8px;
                }
            }
        }
    }
    // Header2 scss
    .header-wrapper2 {
        .right-wrap {
            .store-wrap {
                padding-right: 0px;
                padding-left: 20px;
                @media (max-width: 599px) {
                    padding-right: 10px;
                }                   
                
                .content-block {
                    padding-right: 0px;
                    padding-left: 8px;
                }
            }
            .online-switch-wrap {
                padding-right: 0px;
                padding-left: 12px;
            }
            .language-block {
                margin-right: 0px;
                margin-left: 24px;
            }
            .p2-bold {
                margin-right: 0px;
                margin-left: 8px;
            }
        }
    }
    // language dropdown
    .MuiMenuItem-root {
        .nav-dropdown-content {
            .language-img{
                margin-left: 0px !important;
                margin-right: 8px;
            }
        }
    }
    #language-header-menu{
        .language-img{
            margin-right: 8px;
            margin-left: 0px !important;
        }
    }
    // Footer scss
    .footer-wrapper {
        .mid-wrap {
            .footer-social-wrap {
                .social-icons {
                    margin-left: 0px;
                    margin-right: 24px;
                }
            }
        }
    }
    // Home scss
    // Home section1 scss
    .home-wrapper {
        .home-sec2 {
            .home-sec2-wrap {
                .sec2-boxes-block {
                    .boxes-content-block {
                        padding-right: 0px;
                        padding-left: 24px;
                    }
                }
            }
        }
    }
    // Grocery scss
    // Grocery Searches scss
    .searches-wrapper {
        .searchesleft-wrap {
            .searches-img {
                margin-left: 0px;
                margin-right: 16px;
            }
        }
    }
    .searches-input {
        .MuiOutlinedInput-input {
            padding-right: 14px;
            padding-left: 48px;
        }
    }
    // Grocery Store scss
    .stores-wrapper {
        .store-box {
            .store-content-block {
                .p2-mins {
                    margin: 0 0px 0 8px;
                }
            }
            .store-img-block {
                .store-image {
                    border-radius: 0px 10px 10px 0px;
                }
            }
        }
    }
    .recommended-stores-block {
        .recommended-card-box {
            .recommended-content {
                .p2-mins {
                    margin: 0 0 0 8px;
                }
            }
        }
    }
    // Grocery Products scss
    .products-wrapper {
        .shop-more-block {
            .content-box {
                margin-right: 0px;
                margin-left: 16px;
                .p2 {
                    margin: 0 0px 0 8px;
                }
            }
            .p1 {
                margin: 0 8px 0 0px;
            }
        }
    }
    // Products card
    .products-block {
        .product-box {
            .wishlist {
                left: unset;
                right: 16px;
            }
        }
    }
    // Search Input
    .searches-block {
        margin-right: 20px;
        margin-left: 0px;
        @media (max-width: 599px) {  
            margin-right: 10px;    
        }
        .searches-icon {
            right: unset;
            left: 16px;
        }
    }
    // Business Category scss
    .store-details-wrapper .left-wrap .content-box .open-now {
        margin: 0 8px 0px 0px;
    }
    .store-details-wrapper .left-wrap .content-box .info-icon {
        margin-right: 0px;
        margin-left: 12px;
    }
    .store-details-wrapper .right-wrap .detailside {
        padding-left: 0px;
        margin-left: 0px;
        border-left: 0px solid rgba(169, 172, 170, 0.4);
        padding-right: 24px;
        margin-right: 24px;
        border-right: 1px solid rgba(169, 172, 170, 0.4);
    }
    .store-details-wrapper .right-wrap .detailside:last-child {
        padding-right: 0px;
        margin-right: 0px;
        border-right: none;
    }
    .categories-left-main-wrap {
        border-left: none;
        border-right: 1px solid var(--line);
    }
    .collapse-categories-wrap .clickable-category-block .arrow-left {
        transform: rotate(-90deg);
    }
    .collapse-categories-wrap .clickable-category-block .p1 {
        margin-right: 0px;
        margin-left: 8px;
    }
    .MuiTreeItem-content .MuiTreeItem-iconContainer {
        margin-left: 0px;
        margin-right: 8px !important;
    }
    .collapse-categories-wrap .multi-left {
        padding-right: 0px;
        padding-left: 20px;
    }
    .collapse-categories-wrap .multi-left .check-box {
        padding-right: 0px;
        padding-left: 24px;
    }
    .collapse-categories-wrap .multi-left .check-box .check-label {
        margin-right: 0px;
        margin-left: 10px;
    }
    // material inputs 
    .MuiInputLabel-root{
        text-align: left !important;
        transform-origin: top left !important;
        left: 0 !important;
        right: unset !important;
        transform: translate(14px, 16px) scale(1) !important;
        &.MuiInputLabel-shrink{
            transform: translate(14px, -9px) scale(0.75) !important;
        }
        &.Mui-disabled{        
            transform: translate(11px, -8px) scale(0.75) !important;
            background: #fff !important;
        }
    }
    .MuiFormControl-root{
        .Mui-disabled{
            &::after{
                content: '';
                left: unset;
                right: 12px;
            }
        }
        .MuiInputLabel-root{
            &.Mui-disabled{
                &::after{
                    display: none;
                }
            }
        }
        .MuiOutlinedInput-input{
            &.Mui-disabled{
                &::after{
                    display: none;
                }
            }
        }
    }
    .MuiOutlinedInput-notchedOutline {
        text-align: left !important;
    }
    .MuiSelect-iconOutlined {
        right: 7px !important;
        left: unset;
    }
    // signup
    .signup-wrapper {
        .signup-block {
            .signup-right {
                .checkbox-block {
                    .p2 {
                        margin: 0 4px 0 10px;
                    }
                }
                .uploaded-text-block {
                    padding: 6px 40px 6px 12px;
                    .close-icon {
                        left: unset;
                        right: 12px;
                    }
                }
            }
        }
    }
    .sign-up {
        margin-left: 4px;
    }
    // starting vinay css DIRECTION// 
    .form-group {
        .redspan {
            left: 0px;
            right: auto !important;
        }
    }

    .MuiPaper-root {
        .MuiAlert-icon {
            margin-right: 12px !important;
            margin-left: 0px !important;
        }

        .MuiAlert-action {
            padding: 4px 0px 0px 16px !important;
            margin-left: auto !important;
            margin-right: -8px !important;
        }
    }
    .add-product-head {
        .h3 {
            margin: 0 0px 0px 12px !important;
        }
        .icon24 {            
            transform: scaleX(1) !important;
        }
    }
    // upload multiple
    .uploads-row {
        .upload-multiple {
            margin-right: 16px;
            margin-left: 0px !important;
        }
    }
    // table left content
    .MuiTableCell-head,.MuiTableCell-body {
        text-align: left !important;
        &.MuiTableCell-alignCenter{
            text-align: center !important;  
        }
    }
    .MuiInputAdornment-positionEnd.bg-pink {    
        border-radius: 0px 8px 8px 0px;
        margin-left: 0px;
        right: 1px;
        left: auto;
        border-left: 1px solid #A9ACAA;
        border-right: none;
    }
    .select-chips{        
        margin-left: 0px !important;
        margin-right: 16px !important;
        .MuiChip-deleteIcon{
            color: val(--primary) !important;
            margin: 0 5px 0 -6px !important;
        }
    }
    .MuiChip-deleteIcon {
        margin: 0 5px 0 -6px !important;
    }
    .switch-box {
        .h6 {
            margin: 0 0px 5px 28px;
        }
    }
    .sidebar-wrapper {
        @media (max-width:899px) {
            right: auto !important;
            left: -254px;
        }
        .show-arrow{
            right: -24px;
            left: auto;
            box-shadow: 5px 2px 5px 0px rgb(0 0 0 / 16%);
            border-radius: 0px 10px 10px 0px;
            img.show-arrow-icon{
                transform: rotate(0deg);
            }
        }
        &.show{
            left: 0px;
            img.show-arrow-icon{
                transform: rotate(180deg) !important;
            }
        }
        .sidebar-block {
            .sidebar-links {
                .p2 {
                    margin-right: 0px;
                    margin-left: 10px;
                }
                .notification-signal{
                    right: 16px;
                    left: auto;
                }                
                .ml-auto{
                    margin-left: auto;
                    margin-right: 0;
                }
            }
            .sidebar-links.active {
                .p2 {
                    margin-right: 0px;
                    margin-left: 10px;
                }
            }
        }
    }
    // order detail
    .order-detail-wrapper,.order-listing-wrapper {
        .store-detail-head {
            .h3 {
                margin: 0 12px 0 0px;
            }
            .p2 {
                margin: 0 0px 0 30px;
            }
        }
        .store-bottom-head-block {
            .block-boxes {
                border-right: 0px solid var(--line);
                padding-right: 0px;
                border-left: 1px solid var(--line);
                padding-left: 20px;
                &:first-child {
                    border-right: none;
                    padding-right: 0px;
                    border-left: none;
                    padding-left: 0px;
                }
            }
            .download-box {
                .p2 {
                    margin: 0 0px 0 8px;
                }
            }
        }
        .order-product-detail-wrap {
            .product-blocks {
                .product-blocks-content {
                    padding-right: 0px;
                    padding-left: 8px;
                }
            }
        }
        .order-shipping-detail-wrap {
            .shipping-blocks {
                .p3 {
                    margin: 0 0px 0px 8px;
                }
            }
        }
        .order-order-tracking-wrap {
            .order-tracking-block {
                .order-tracking-list {
                    .dot-circle {
                        &::after {
                            content: "";
                            margin-right: 0px;
                            margin-left: 4px;
                        }
                    }
                    &.success {
                        .dot-circle {
                            &::after {
                                content: "";
                                margin-right: 0px;
                                margin-left: 4px;
                            }
                        }
                    }
                    .p2 {
                       margin: 0 0px 0 24px;
                    }
                }
            }
        }
        
    }
    // Footer scss
    .footer-wrapper {
        .mid-wrap {
            .footer-social-wrap {
                .social-icons {
                    margin-left: 0px;
                    margin-right: 16px;
                }
            }
            .img-height41 + .img-height41{
                margin-left: 7px;
                margin-right: 0px;
            }
        }
    }
    // uploaded text wrap
    .uploaded-text-block {
        padding: 6px 40px 6px 12px !important;
        .close-icon {
            left: unset !important;
            right: 12px;
        }
    }
    
    // store detail
    .store-detail-wrapper {
        .signup-right {
            .checkbox-block {
                .p2 {
                    margin: 0 4px 0 10px;
                }
            }
        }
        .my-stores-wrap {
            .content-block {
                padding-right: 0px;
                padding-left: 16px;
            }
        }
        .date-picker-btn {
            margin-right: 0px;
            margin-left: 12px;
        }
        .store-detail-tabs-block {
            .card-white {
                padding: 20px 0px 86px 0px;
                .MuiButtonBase-root{
                    padding: 0px 20px !important;
                }
            }
        }
    }
    // vertical tabs block
    .MuiTabs-vertical{
        .MuiTab-textColorPrimary {
            padding: 0px 0px 0px 16px !important;
        }
        .MuiTabs-indicator{
            right: unset;
            left: 0;
        }
    }
    // react prime dropdown
    .p-float-label > label {
        left: 0.75rem;
        right: auto;
    }
    .p-float-label .p-inputwrapper-filled ~ label, 
    .p-float-label .p-inputwrapper-focus ~ label {
        left: 0.75rem;
        right: auto;
    }
    // add product
    .add-product-wrapper {
        .card-white {
            .switch-box {
                .h6 {
                    margin: 0 28px 5px 0px;
                }
            }
        }
    }    
    .uom-content{
        .switch-box {
            .h6 {
                margin: 0px 0px;
            }
        }
        .uploads-row {
            .upload-multiple {
                .delete-icon {
                    right: 6px !important;
                    left: auto !important;
                }
            }
        }
    }
    .calender-icon{    
        .MuiSelect-select,.MuiInputBase-root{
            &::before{
                right: 9px;
                left: auto;
            }
        }
    }
    // clock-picker
    .clock-picker{
        position: relative;
        .MuiInputLabel-root{            
            transform: translate(11px, -8px) scale(0.75) !important;
        }
        .MuiSelect-iconOutlined{
            left: auto !important;
            right: 10px !important;
            @media (max-width: 767px){
                right: 35px !important;
            }
        }
        .Mui-disabled {
            &::after{
                left: auto !important;
                right: 10px !important;
            }
        }
        
        .am-pm-drop{
            right: 2px;
            left: auto;
            &::before{
                content: '';
                position: absolute;
                left: 2px;
                right: auto;
            }
        }
    }
    .text-right-left{
        text-align: right;
    }
    .slick-prev{
        left: -16px !important;
        right: auto !important;
        transform: rotate(180deg);
    }
    .slick-next{
        right: -16px !important;
        left: auto !important;
        transform: rotate(180deg);
    }
    // for droup shoe only box    
    .p-dropdown.onShowDrop{
        text-align: left;
        &::after {
            right: 13px;
            left: auto;
        }
        &~label {
            left: 0.75rem;
            right: auto;
        }
    }
    // my-order
    .new-dot{
        &::after{
            content: '';
            right: 10px;
            left: auto;
        }
    }
    .table-type-parent {
        .table-type-body {
            .table-type-body-inner-flex {
                .left-detail-box {
                    padding-right: 20px;
                    padding-left: 0px;
                    .order-tracking-box {
                        .order-status {
                            &::before{
                                left: 0px;
                                right: auto;
                            }
                        }
                    }
                }
                .right-detail-box{
                    border-right: 0px;
                    border-left: 1px solid var(--line);
                    padding-right: 0px;
                    padding-left: 20px;
                    @media (max-width: 1280px) {
                        width: 100%;
                        padding-right: 20px !important;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        border-left: 0 !important;
                    }
                    @media (max-width: 991px) {
                        padding-left: 0px;
                        min-width: 100%;
                        width: 100%;
                        border-left: none;
                    }
                }
            }
        }
    }
    // MODAL
    .modal-close{
        left: auto;
        right: 16px;
    }
    
    // datePicker mobile
    .datepicker-icon{
        &::before{
            right: 10px;
            left: auto;
        }
    }
    .profile-drop-parent{
        border-left: 1px solid var(--line);
        border-right: none !important;
        margin-right: 0px !important;
        margin-left: 20px;
    }
    .payout-status{
        .MuiSelect-select{            
            padding-left: 0;
            padding-right: 30px !important;
        }
    }
    // table
    .MuiTableCell-root{
        text-align: left;
    }
    .ck-editor{
        direction: ltr;
        .ck-editor__editable_inline{
            text-align: left !important; 
        }
    }
    .short-div{
        .short-box {
            margin-left: 4px;
            margin-right: 0px;
        }
    }    
    .rotate180{
        transform: rotate(0deg);
        cursor: pointer;
    }
    .language-menu{
        left: auto !important;
        right: 102% !important;
    }
    .total-order-text{
        left: auto !important;
        right: 0px;
    }
    .main-mid-wrapper{
        @media (max-width: 1199px) {
            padding: 100px  20px 0px 20px;
        }
    }
    .MuiTabScrollButton-root{
        transform: rotate(0deg);
        top: 0px;
    }
    
    .MuiInputAdornment-positionEnd.bg-pink {
        .MuiSelect-select{
            padding: 0px 20px 0px 0px !important;
        }
        .MuiSelect-iconOutlined {
            left: auto !important;
            right: -8px !important;
        }
    }
    .faq-condition-wrapper .container-body .faq-condition-body .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link .p-accordion-header-text{
        margin-right: 20px;
        margin-left: 0px;   
    }
    .faq-condition-wrapper .container-body .faq-condition-body .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link::after{
        right: 16px !important;
        left: auto !important;
    }
    .order-listing-wrapper , .manage-category-wrapper{
        .searches-block {
            .MuiOutlinedInput-input {
                padding: 6.5px 12px 6.5px 48px !important;
            }
        }
    }
    .addon-delete-row{
        @media (max-width: 599px) {            
            padding-left: 0px !important;
            padding-right: 20px !important;
        }
        .delete-icon{
            right: -30px !important;
            left: auto !important;
            @media (max-width: 599px) {        
                right: -5px !important;
            }
        }
    }    
    .MuiAutocomplete-endAdornment{
        right: 6px !important;
        left: auto !important;
    }   
    .uom-content{
        .add-on-select-option {
            .MuiOutlinedInput-root{
                padding-left: 9px !important;
                padding-right: 65px !important;
            }
        }
    }
    .manage-category-wrapper .left-side-new .MuiTreeView-root .MuiTreeItem-root{
        &.main-tree {
            .MuiTreeItem-content{
                padding: 8px 34px 8px 6px !important;
                .MuiTreeItem-iconContainer{
                    left: auto;
                    right: 8px;
                }
            }
        }
    }
    .manage-category-wrapper {
        .category-boxedinner {
            padding: 8px 34px 8px 6px !important;
        }
    }
    .manage-category-wrapper .left-side-new .MuiTreeView-root .MuiTreeItem-root.main-tree .MuiCollapse-root .MuiCollapse-wrapperInner .MuiTreeItem-root .MuiTreeItem-content{
        padding: 8px 34px 8px 20px !important;
    }
    .category-img-row{
        .upload-btn{
            margin: 0px 12px 0px 24px;
            @media (max-width: 480px) {
                margin: 20px 0px 6px 0px;
            }
        }
    }
    .offer-item-box .relative + .relative{
        border-right: none !important;
        border-left: 1px solid var(--Grey, #A9ACAA);
        @media (max-width: 767px) {
            border-left: none;
        }
    }
    .text-left{
        text-align: right;
    }
}
